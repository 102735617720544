.button, .button-red {
  background: $light-yellow url(/img/common/bg_btn.png) center no-repeat;
  background-size: 1355px auto;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
  @include mq(sp) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  a {
    display: inline-block;
    width: 780px;
    padding: 24px 0;
    border: 2px solid #fff;
    border-radius: 100px;
    background: url(/img/common/icn_btn_wh.svg) 70px center no-repeat, $grad1;
    color: #fff;
    font-size: 32px;
    font-weight: 900;
    text-decoration: none;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    @include mq(sp) {
      width: 90vw;
      padding: 13px 0;
      font-size: 20px;
      background-position: 30px center, 0 0;
      background-size: 12px 16px, auto;
    }
    span {
      color: $yellow;
      transition: .5s;
    }
    &:hover {
      @include mq(pc) {
        background: url(/img/common/icn_btn_red.svg) 70px center no-repeat, $grad2;
        color: $pink;
      }
      span {
        @include mq(pc) {
          color: $pink;
        }
      }
    }
  }
}

.button-green {
  background: $light-yellow url(/img/common/bg_btn.png) center no-repeat;
  background-size: 1355px auto;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
  @include mq(sp) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  a {
    display: inline-block;
    width: 780px;
    padding: 24px 0;
    border: 2px solid #fff;
    border-radius: 100px;
    background: url(/img/common/icn_btn_wh.svg) 70px center no-repeat, $grad3;
    color: #fff;
    font-size: 32px;
    font-weight: 900;
    text-decoration: none;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    @include mq(sp) {
      width: 90vw;
      padding: 13px 0;
      font-size: 20px;
      background-position: 30px center, 0 0;
      background-size: 12px 16px, auto;
    }
    span {
      color: #fff;
      transition: .5s;
    }
    &:hover {
      @include mq(pc) {
        background: url(/img/common/icn_btn_orn.svg) 70px center no-repeat, $grad2;
        color: $orange;
      }
    }
  }
}

.button-orange {
  background: $light-yellow url(/img/common/bg_btn.png) center no-repeat;
  background-size: 1355px auto;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
  @include mq(sp) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  a {
    display: inline-block;
    width: 780px;
    padding: 24px 0;
    border: 2px solid #fff;
    border-radius: 100px;
    background: url(/img/common/icn_btn_wh.svg) 70px center no-repeat, $grad4;
    color: #fff;
    font-size: 32px;
    font-weight: 900;
    text-decoration: none;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    @include mq(sp) {
      width: 90vw;
      padding: 13px 0;
      font-size: 20px;
      background-position: 30px center, 0 0;
      background-size: 12px 16px, auto;
    }
    span {
      color: #fff;
      transition: .5s;
    }
    &:hover {
      @include mq(pc) {
        background: url(/img/common/icn_btn_orn.svg) 70px center no-repeat, $grad2;
        color: $orange;
      }
    }
  }
}

.button-s, .button-s-red {
  padding-top: 50px;
  padding-bottom: 80px;
  text-align: center;
  @include mq(sp) {
    padding-top: 16px;
    padding-bottom: 40px;
  }
  a {
    display: inline-block;
    width: 420px;
    padding: 18px 0;
    border: 2px solid #fff;
    border-radius: 100px;
    background: url(/img/common/icn_btn_wh.svg) 40px center no-repeat, $grad1;
    color: #fff;
    font-size: 24px;
    font-weight: 900;
    text-decoration: none;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    @include mq(sp) {
      width: 90vw;
      padding: 13px 0;
      font-size: 20px;
      background-position: 30px center, 0 0;
      background-size: 12px 16px, auto;
    }
    span {
      color: #fff;
      transition: .5s;
    }
    &:hover {
      @include mq(pc) {
        background: url(/img/common/icn_btn_red.svg) 40px center no-repeat, $grad2;
        color: $pink;
      }
      span {
        @include mq(pc) {
          color: $pink;
        }
      }
    }
  }
}

.button-s-green {
  padding-top: 50px;
  padding-bottom: 80px;
  text-align: center;
  @include mq(sp) {
    padding-top: 16px;
    padding-bottom: 40px;
  }
  a {
    display: inline-block;
    width: 420px;
    padding: 18px 0;
    border: 2px solid #fff;
    border-radius: 100px;
    background: url(/img/common/icn_btn_wh.svg) 40px center no-repeat, $grad3;
    color: #fff;
    font-size: 24px;
    font-weight: 900;
    text-decoration: none;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    @include mq(sp) {
      width: 90vw;
      padding: 13px 0;
      font-size: 20px;
      background-position: 30px center, 0 0;
      background-size: 12px 16px, auto;
    }
    span {
      color: #fff;
      transition: .5s;
    }
    &:hover {
      @include mq(pc) {
        background: url(/img/common/icn_btn_orn.svg) 40px center no-repeat, $grad2;
        color: $orange;
      }
      span {
        @include mq(pc) {
          color: $orange;
        }
      }
    }
  }
}

.button-s-orange {
  padding-top: 50px;
  padding-bottom: 80px;
  text-align: center;
  @include mq(sp) {
    padding-top: 16px;
    padding-bottom: 40px;
  }
  a {
    display: inline-block;
    width: 420px;
    padding: 18px 0;
    border: 2px solid #fff;
    border-radius: 100px;
    background: url(/img/common/icn_btn_wh.svg) 40px center no-repeat, $grad4;
    color: #fff;
    font-size: 24px;
    font-weight: 900;
    text-decoration: none;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    @include mq(sp) {
      width: 90vw;
      padding: 13px 0;
      font-size: 20px;
      background-position: 30px center, 0 0;
      background-size: 12px 16px, auto;
    }
    span {
      color: #fff;
      transition: .5s;
    }
    &:hover {
      @include mq(pc) {
        background: url(/img/common/icn_btn_orn.svg) 40px center no-repeat, $grad2;
        color: $orange;
      }
      span {
        @include mq(pc) {
          color: $orange;
        }
      }
    }
  }
}