.bgt-box__image-container {
  text-align: center;
}
.bgt-box__caption {
  font-size: 18px;
  text-align: center;
}

.bgt-ckeditor-container {
  a:not(.bgt-btn) {
    &:hover {
      @include mq(pc) {
      }
    }
  }
}

.bge-contents {
  [data-bgb=hr] {
    @include base(1140,20,5vw);
  }
}

// レスポンシブ切り替え対応
.bge-contents.bge_contents.bge_content {
  .responsive {
    &_pc {
      position: relative;
      padding-bottom: 10px;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: auto;
        z-index: 100;
        content: "　パソコン版　";
        width: auto;
        height: auto;
        background: #ff0;
        color: #000;
        font-size: 16px;
        line-height: 1.5;
      }
    }
    &_sp {
      position: relative;
      text-align: center;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: auto;
        z-index: 100;
        content: "　スマホ版　";
        width: auto;
        height: auto;
        background: #ff0;
        color: #000;
        font-size: 16px;
        line-height: 1.5;
      }
      img {
        width: 40%;
      }
    }
  }
}
.wrapper .main .bge-contents {
  .responsive {
    &_pc {
      display: block;
      @include mq(sp) {
        display: none;
      }
    }
    &_sp {
      display: none;
      @include mq(sp) {
        display: block;
      }
    }
  }
}

// flexbox
.flex-block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .flex-grid {
    width: 48%;
    @include mq(sp) {
      width: 100%;
      margin-bottom: 20px;
    }
    &:last-child {
      @include mq(sp) {
        margin-bottom: 0;
      }
    }
    .bgt-image-container {
      margin-bottom: 10px;
    }
    .bgt-ckeditor-container {
    }
  }
}

// 3カラムWYSIWYG
[data-bgb=wysiwyg3] {
  display: flex;
  @include mq(sp) {
    display: block;
  }
  .bgt-grid {
    width: 32%;
    margin-right: 2%;
    @include mq(sp) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
    &--last {
      margin-right: 0;
    }
  }
}

.btn-def [data-bgt-button-kind='link'] {
}

[data-bgb=button].red {
  background: $light-yellow url(/img/common/bg_btn.png) center no-repeat;
  background-size: 1355px auto;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
  @include mq(sp) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  a {
    display: inline-block;
    width: 780px;
    padding: 24px 0;
    border: 2px solid #fff;
    border-radius: 100px;
    background: url(/img/common/icn_btn_wh.svg) 70px center no-repeat, $grad1;
    color: #fff;
    font-size: 32px;
    font-weight: 900;
    text-decoration: none;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    @include mq(sp) {
      width: 90vw;
      padding: 13px 0;
      font-size: 20px;
      background-position: 30px center, 0 0;
      background-size: 12px 16px, auto;
    }
    span.yellow {
      color: $yellow;
      transition: .5s;
    }
    &:hover {
      @include mq(pc) {
        background: url(/img/common/icn_btn_red.svg) 70px center no-repeat, $grad2;
        color: $pink;
      }
      span.yellow {
        @include mq(pc) {
          color: $pink;
        }
      }
    }
  }
}

[data-bgb=button].green {
  background: $light-yellow url(/img/common/bg_btn.png) center no-repeat;
  background-size: 1355px auto;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
  @include mq(sp) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  a {
    display: inline-block;
    width: 780px;
    padding: 24px 0;
    border: 2px solid #fff;
    border-radius: 100px;
    background: url(/img/common/icn_btn_wh.svg) 70px center no-repeat, $grad3;
    color: #fff;
    font-size: 32px;
    font-weight: 900;
    text-decoration: none;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    @include mq(sp) {
      width: 90vw;
      padding: 13px 0;
      font-size: 20px;
      background-position: 30px center, 0 0;
      background-size: 12px 16px, auto;
    }
    span.yellow {
      color: #fff;
      transition: .5s;
    }
    &:hover {
      @include mq(pc) {
        background: url(/img/common/icn_btn_orn.svg) 70px center no-repeat, $grad2;
        color: $orange;
      }
      span.yellow {
        @include mq(pc) {
          color: $orange;
        }
      }
    }
  }
}

[data-bgb=button].orange {
  background: $light-yellow url(/img/common/bg_btn.png) center no-repeat;
  background-size: 1355px auto;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
  @include mq(sp) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  a {
    display: inline-block;
    width: 780px;
    padding: 24px 0;
    border: 2px solid #fff;
    border-radius: 100px;
    background: url(/img/common/icn_btn_wh.svg) 70px center no-repeat, $grad4;
    color: #fff;
    font-size: 32px;
    font-weight: 900;
    text-decoration: none;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    @include mq(sp) {
      width: 90vw;
      padding: 13px 0;
      font-size: 20px;
      background-position: 30px center, 0 0;
      background-size: 12px 16px, auto;
    }
    span.yellow {
      color: #fff;
      transition: .5s;
    }
    &:hover {
      @include mq(pc) {
        background: url(/img/common/icn_btn_orn.svg) 70px center no-repeat, $grad2;
        color: $orange;
      }
      span.yellow {
        @include mq(pc) {
          color: $orange;
        }
      }
    }
  }
}

[data-bgb=button].s-red {
  padding-top: 50px;
  padding-bottom: 80px;
  text-align: center;
  @include mq(sp) {
    padding-top: 16px;
    padding-bottom: 40px;
  }
  a {
    display: inline-block;
    width: 420px;
    padding: 18px 0;
    border: 2px solid #fff;
    border-radius: 100px;
    background: url(/img/common/icn_btn_wh.svg) 40px center no-repeat, $grad1;
    color: #fff;
    font-size: 24px;
    font-weight: 900;
    text-decoration: none;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    @include mq(sp) {
      width: 90vw;
      padding: 13px 0;
      font-size: 20px;
      background-position: 30px center, 0 0;
      background-size: 12px 16px, auto;
    }
    span {
      color: #fff;
      transition: .5s;
    }
    &:hover {
      @include mq(pc) {
        background: url(/img/common/icn_btn_red.svg) 40px center no-repeat, $grad2;
        color: $pink;
      }
      span {
        @include mq(pc) {
          color: $pink;
        }
      }
    }
  }
}

[data-bgb=button].s-green {
  padding-top: 50px;
  padding-bottom: 80px;
  text-align: center;
  @include mq(sp) {
    padding-top: 16px;
    padding-bottom: 40px;
  }
  a {
    display: inline-block;
    width: 420px;
    padding: 18px 0;
    border: 2px solid #fff;
    border-radius: 100px;
    background: url(/img/common/icn_btn_wh.svg) 40px center no-repeat, $grad3;
    color: #fff;
    font-size: 24px;
    font-weight: 900;
    text-decoration: none;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    @include mq(sp) {
      width: 90vw;
      padding: 13px 0;
      font-size: 20px;
      background-position: 30px center, 0 0;
      background-size: 12px 16px, auto;
    }
    span {
      color: #fff;
      transition: .5s;
    }
    &:hover {
      @include mq(pc) {
        background: url(/img/common/icn_btn_orn.svg) 40px center no-repeat, $grad2;
        color: $orange;
      }
      span {
        @include mq(pc) {
          color: $orange;
        }
      }
    }
  }
}

[data-bgb=button].s-orange {
  padding-top: 50px;
  padding-bottom: 80px;
  text-align: center;
  @include mq(sp) {
    padding-top: 16px;
    padding-bottom: 40px;
  }
  a {
    display: inline-block;
    width: 420px;
    padding: 18px 0;
    border: 2px solid #fff;
    border-radius: 100px;
    background: url(/img/common/icn_btn_wh.svg) 40px center no-repeat, $grad4;
    color: #fff;
    font-size: 24px;
    font-weight: 900;
    text-decoration: none;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    @include mq(sp) {
      width: 90vw;
      padding: 13px 0;
      font-size: 20px;
      background-position: 30px center, 0 0;
      background-size: 12px 16px, auto;
    }
    span {
      color: #fff;
      transition: .5s;
    }
    &:hover {
      @include mq(pc) {
        background: url(/img/common/icn_btn_orn.svg) 40px center no-repeat, $grad2;
        color: $orange;
      }
      span {
        @include mq(pc) {
          color: $orange;
        }
      }
    }
  }
}

[data-bgb=button-red] {
  background: $light-yellow url(/img/common/bg_btn.png) center no-repeat;
  background-size: 1355px auto;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
  @include mq(sp) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  a.bgt-btn {
    display: inline-block;
    width: 780px;
    padding: 24px 0;
    border: 2px solid #fff;
    border-radius: 100px;
    background: url(/img/common/icn_btn_wh.svg) 70px center no-repeat, $grad1;
    color: #fff;
    font-size: 32px;
    font-weight: 900;
    text-decoration: none;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    @include mq(sp) {
      width: 90vw;
      padding: 13px 0;
      font-size: 20px;
      background-position: 30px center, 0 0;
      background-size: 12px 16px, auto;
    }
    span.yellow {
      color: $yellow;
      transition: .5s;
    }
    &:hover {
      @include mq(pc) {
        background: url(/img/common/icn_btn_red.svg) 70px center no-repeat, $grad2;
        color: $pink;
      }
      span.yellow {
        @include mq(pc) {
          color: $pink;
        }
      }
    }
  }
}

[data-bgb=button-green] {
  background: $light-yellow url(/img/common/bg_btn.png) center no-repeat;
  background-size: 1355px auto;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
  @include mq(sp) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  a.bgt-btn {
    display: inline-block;
    width: 780px;
    padding: 24px 0;
    border: 2px solid #fff;
    border-radius: 100px;
    background: url(/img/common/icn_btn_wh.svg) 70px center no-repeat, $grad3;
    color: #fff;
    font-size: 32px;
    font-weight: 900;
    text-decoration: none;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    @include mq(sp) {
      width: 90vw;
      padding: 13px 0;
      font-size: 20px;
      background-position: 30px center, 0 0;
      background-size: 12px 16px, auto;
    }
    span.yellow {
      color: #fff;
      transition: .5s;
    }
    &:hover {
      @include mq(pc) {
        background: url(/img/common/icn_btn_orn.svg) 70px center no-repeat, $grad2;
        color: $orange;
      }
      span.yellow {
        @include mq(pc) {
          color: $orange;
        }
      }
    }
  }
}

[data-bgb=button-orange] {
  background: $light-yellow url(/img/common/bg_btn.png) center no-repeat;
  background-size: 1355px auto;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
  @include mq(sp) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  a.bgt-btn {
    display: inline-block;
    width: 780px;
    padding: 24px 0;
    border: 2px solid #fff;
    border-radius: 100px;
    background: url(/img/common/icn_btn_wh.svg) 70px center no-repeat, $grad4;
    color: #fff;
    font-size: 32px;
    font-weight: 900;
    text-decoration: none;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    @include mq(sp) {
      width: 90vw;
      padding: 13px 0;
      font-size: 20px;
      background-position: 30px center, 0 0;
      background-size: 12px 16px, auto;
    }
    span.yellow {
      color: #fff;
      transition: .5s;
    }
    &:hover {
      @include mq(pc) {
        background: url(/img/common/icn_btn_orn.svg) 70px center no-repeat, $grad2;
        color: $orange;
      }
      span.yellow {
        @include mq(pc) {
          color: $orange;
        }
      }
    }
  }
}

[data-bgb=button-s-red] {
  padding-top: 50px;
  padding-bottom: 80px;
  text-align: center;
  @include mq(sp) {
    padding-top: 16px;
    padding-bottom: 40px;
  }
  a.bgt-btn {
    display: inline-block;
    width: 420px;
    padding: 18px 0;
    border: 2px solid #fff;
    border-radius: 100px;
    background: url(/img/common/icn_btn_wh.svg) 40px center no-repeat, $grad1;
    color: #fff;
    font-size: 24px;
    font-weight: 900;
    text-decoration: none;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    @include mq(sp) {
      width: 90vw;
      padding: 13px 0;
      font-size: 20px;
      background-position: 30px center, 0 0;
      background-size: 12px 16px, auto;
    }
    span {
      color: #fff;
      transition: .5s;
    }
    &:hover {
      @include mq(pc) {
        background: url(/img/common/icn_btn_red.svg) 40px center no-repeat, $grad2;
        color: $pink;
      }
      span {
        @include mq(pc) {
          color: $pink;
        }
      }
    }
  }
}

[data-bgb=button-s-green] {
  padding-top: 50px;
  padding-bottom: 80px;
  text-align: center;
  @include mq(sp) {
    padding-top: 16px;
    padding-bottom: 40px;
  }
  a.bgt-btn {
    display: inline-block;
    width: 420px;
    padding: 18px 0;
    border: 2px solid #fff;
    border-radius: 100px;
    background: url(/img/common/icn_btn_wh.svg) 40px center no-repeat, $grad3;
    color: #fff;
    font-size: 24px;
    font-weight: 900;
    text-decoration: none;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    @include mq(sp) {
      width: 90vw;
      padding: 13px 0;
      font-size: 20px;
      background-position: 30px center, 0 0;
      background-size: 12px 16px, auto;
    }
    span {
      color: #fff;
      transition: .5s;
    }
    &:hover {
      @include mq(pc) {
        background: url(/img/common/icn_btn_orn.svg) 40px center no-repeat, $grad2;
        color: $orange;
      }
      span {
        @include mq(pc) {
          color: $orange;
        }
      }
    }
  }
}

[data-bgb=button-s-orange] {
  padding-top: 50px;
  padding-bottom: 80px;
  text-align: center;
  @include mq(sp) {
    padding-top: 16px;
    padding-bottom: 40px;
  }
  a.bgt-btn {
    display: inline-block;
    width: 420px;
    padding: 18px 0;
    border: 2px solid #fff;
    border-radius: 100px;
    background: url(/img/common/icn_btn_wh.svg) 40px center no-repeat, $grad4;
    color: #fff;
    font-size: 24px;
    font-weight: 900;
    text-decoration: none;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    @include mq(sp) {
      width: 90vw;
      padding: 13px 0;
      font-size: 20px;
      background-position: 30px center, 0 0;
      background-size: 12px 16px, auto;
    }
    span {
      color: #fff;
      transition: .5s;
    }
    &:hover {
      @include mq(pc) {
        background: url(/img/common/icn_btn_orn.svg) 40px center no-repeat, $grad2;
        color: $orange;
      }
      span {
        @include mq(pc) {
          color: $orange;
        }
      }
    }
  }
}

[data-bgb].responsive {
  table {
    th {
      @include mq(sp) {
        display: block;
        width: 100%;
      }
    }
    td {
      @include mq(sp) {
        display: block;
        width: 100%;
      }
    }
  }
}


[data-bgb=button2] .bgt-grid--last, [data-bgb=download-file2] .bgt-grid--last, [data-bgb=image2] .bgt-grid--last, [data-bgb=image-link2] .bgt-grid--last, [data-bgb=image-text2] .bgt-grid--last, [data-bgb=image-link-text2] .bgt-grid--last, [data-bgb=trimmed-image2] .bgt-grid--last, [data-bgb=trimmed-image-link2] .bgt-grid--last, [data-bgb=wysiwyg2] .bgt-grid--last {
    padding-left: 30px;
    @include mq(sp) {
      padding-left: 0;
    }
}
[data-bgb=button2] .bgt-grid--first, [data-bgb=download-file2] .bgt-grid--first, [data-bgb=image2] .bgt-grid--first, [data-bgb=image-link2] .bgt-grid--first, [data-bgb=image-text2] .bgt-grid--first, [data-bgb=image-link-text2] .bgt-grid--first, [data-bgb=trimmed-image2] .bgt-grid--first, [data-bgb=trimmed-image-link2] .bgt-grid--first, [data-bgb=wysiwyg2] .bgt-grid--first {
    padding-right: 30px;
    @include mq(sp) {
      padding-right: 0;
    }
}

[data-bgb=text-image2] .bgt-grid--first {
  padding-right: 20px;
  @include mq(sp) {
    padding-right: 0;
  }
}
[data-bgb=text-image2] .bgt-grid--last {
  padding-left: 20px;
  @include mq(sp) {
    padding-left: 0;
  }
}

[data-bgb=wysiwyg2] .bgt-grid [data-bgt] > :last-child {
  margin-bottom: 0 !important;
}

[data-bgb] .bgt-grid {
  @include mq(sp) {
    margin-bottom: 20px;
  }
  &--last {
    @include mq(sp) {
      margin-bottom: 0;
    }
  }
}
