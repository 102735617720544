@mixin opacity($opacity) {
	opacity: $opacity;
	filter: alpha(opacity=($opacity * 100));
}

@mixin clearfix() {
	&:before,
	&:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}
}

@mixin box-sizing($type) {
	-webkit-box-sizing:$type;
	-moz-box-sizing:$type;
	box-sizing:$type;
}

@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius;
}

@mixin base($width:1096,$pc:20,$sp:8vw) {
  max-width: $width * 1px;
  margin-left: auto;
  margin-right: auto;
  padding-left: $pc * 1px;
  padding-right: $pc * 1px;
  @include mq(sp) {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: $sp;
    padding-right: $sp;
  }
}

@mixin base-wrap {
	max-width: 1136px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  @include mq(sp) {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
@mixin base-wrap--sp {
  max-width: 1136px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  @include mq(sp) {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 8vw;
    padding-right: 8vw;
  }
}

@mixin box-wrap {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  @include mq(tab) {
    margin-left: 1%;
    margin-right: 1%;
  }
  @include mq(sp) {
    width: auto;
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}
@mixin box-wrap--sp {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  @include mq(tab) {
    margin-left: 1%;
    margin-right: 1%;
  }
  @include mq(sp) {
    width: auto;
    width: auto;
    margin-left: 5vw;
    margin-right: 5vw;
  }
}

@mixin arrow {
  position: relative;
  &::before {
    position: absolute;
    left: 0;
    top: 50%;
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-top: 1px solid $main;
    border-right: 1px solid $main;
    transform: rotate(45deg) translateY(-50%);
    @include mq(sp) {
      width: 6px;
      height: 6px;
    }
  }
}
