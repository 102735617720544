.table-scroll {
  overflow: auto;
  white-space: nowrap;
  &::-webkit-scrollbar {
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #F1F1F1;
  }
  &::-webkit-scrollbar-thumb {
    background: #BCBCBC;
  }

  table {
    th {
      @include mq(sp) {
        display: table-cell !important;
        width: auto !important;
      }
    }
    td {
      @include mq(sp) {
        display: table-cell !important;
        width: auto !important;
      }
    }
  }
}


.not-scroll {
  table {
    th {
      @include mq(sp) {
        display: block;
        width: 100%;
        padding: 10px 15px;
      }
    }
    td {
      @include mq(sp) {
        display: block;
        width: 100%;
        padding: 15px 0;
      }
    }
  }
}

.table-01 {
  @include box-sizing(border-box);
  margin-bottom: 60px;
  border-top: 1px solid $gray;
  tr {
    border-bottom: 1px solid $gray;
  }
  th {
    @include box-sizing(border-box);
    width: 20%;
    padding: 20px;
    background: $light-gray;
    color: $black;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    vertical-align: top;
    @include mq(sp) {
      width: auto;
      line-height: 1.2;
    }
  }
  td {
    @include box-sizing(border-box);
    padding: 20px;
    font-size: 15px;
    line-height: 1.5;
    @include mq(sp) {
      font-size: 15px;
    }
  }
}
