// mv
.block01 {
  background: $yellow;
  &_inner {
    @include base(1440,20,5vw);
    min-width: 1200px;
    @include mq(sp) {
      min-width: auto;
    }
  }
  &_box01 {
    position: relative;
  }
  &_title {
    margin-bottom: 50px;
    padding-top: 80px;
    text-align: center;
    @include mq(sp) {
      padding-top: 60px;
      margin-bottom: 25px;
    }
  }
  &_text01 {
    margin-bottom: 10px;
    font-size: 36px;
    font-weight: 700;
    @include mq(sp) {
      font-size: 16px;
    }
    span {
      position: relative;
      display: inline-block;
      padding: 0 30px;
      @include mq(sp) {
        padding: 0 16px;
      }
      &::before {
        position: absolute;
        top: 22px;
        left: 0;
        content: "";
        display: block;
        width: 2px;
        height: 30px;
        background: $black;
        transform: rotate(-30deg);
        @include mq(sp) {
          top: 10px;
          width: 1px;
          height: 18px;
        }
      }
      &::after {
        position: absolute;
        top: 22px;
        right: 0;
        content: "";
        display: block;
        width: 2px;
        height: 30px;
        background: $black;
        transform: rotate(30deg);
        @include mq(sp) {
          top: 10px;
          width: 1px;
          height: 18px;
        }
      }
    }
  }
  &_text02 {
    margin-bottom: 20px;
    font-size: 56px;
    font-weight: 900;
    line-height: 1;
    @include mq(sp) {
      font-size: 18px;
    }
    &_ja {
      color: $blue;
    }
    &_en {
      color: $pink;
    }
  }
  &_text03 {
    font-size: 78px;
    font-weight: 900;
    line-height: 1;
    @include mq(sp) {
      font-size: 25px;
    }
  }
  &_text04 {
    margin-bottom: 40px;
    font-size: 26px;
    font-weight: 700;
    letter-spacing: .1em;
    line-height: 1.6;
    text-align: center;
    @include mq(sp) {
      margin-bottom: 20px;
      font-size: 14px;
      text-align: left;
    }
  }
  &_image01 {
    position: absolute;
    left: -30px;
    bottom: 23px;
    width: 313px;
    @include mq(sp) {
      position: static;
      width: 130px;
      margin-left: 20px;
    }
  }
  &_image02 {
    position: absolute;
    right: -100px;
    bottom: -16px;
    width: 400px;
    @include mq(sp) {
      top: -64px;
      right: 0;
      bottom: auto;
      width: 180px;
    }
  }
  &_image03 {
    width: 545px;
    margin: 0 auto;
    padding-bottom: 34px;
    @include mq(sp) {
      width: 90vw;
      padding-bottom: 20px;
    }
  }
}

// probrem
.block02 {
  margin-top: 60px;
  margin-bottom: 60px;
  @include mq(sp) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  &_title01 {
    font-size: 36px;
    font-weight: 700;
    line-height: 1.4;
    text-align: center;
    @include mq(sp) {
      font-size: 14px;
    }
    span {
      color: #1C909F;
    }
  }
  &_image01 {
    position: relative;
    margin-bottom: 70px;
    padding-top: 46px;
    padding-bottom: 60px;
    background: rgb(239,251,252);
    background: linear-gradient(0deg, rgba(239,251,252,1) 0%, rgba(255,255,255,1) 100%);
    text-align: center;
    @include mq(sp) {
      margin-bottom: 35px;
      padding-top: 30px;
      padding-bottom: 30px;
    }
    &::after {
      position: absolute;
      bottom: -65px;
      left: 50%;
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 65px 80px 0 80px;
      border-color: #effbfc transparent transparent transparent;
      transform: translateX(-50%);
      @include mq(sp) {
        bottom: -27px;
        border-width: 27px 34px 0 34px;
      }
    }
    img {
      width: 86%;
      @include mq(sp) {
        width: 100%;
      }
    }
  }

  &_title02 {
    @include base(1140,20,5vw);
    margin-bottom: 46px;
    font-size: 46px;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    @include mq(sp) {
      margin-bottom: 30px;
      font-size: 20px;
    }
    span {
      display: inline-block;
      border-bottom: 4px solid $pink;
      color: $pink;
      @include mq(sp) {
        border-width: 2px;
      }
    }
  }
  &_box01 {
    @include flex(nowrap);
    @include base(1100,0,0);
    padding: 35px 30px;
    border: 4px solid $yellow;
    border-radius: 10px;
    background: $light-yellow;
    line-height: 1.5;
    @include mq(sp) {
      margin-left: 5vw;
      margin-right: 5vw;
      padding: 22px 10px;
    }
  }
  &_text01 {
    display: inline-block;
    margin-right: 30px;
    padding: 0 40px;
    border-radius: 6px;
    background: #F6AB0F;
    color: #fff;
    font-size: 26px;
    font-weight: 700;
    @include mq(sp) {
      display: block;
      width: 40vw;
      margin: 0 auto 5px;
      padding: 6px 0;
      font-size: 17px;
      text-align: center;
    }
  }
  &_text02 {
    font-size: 30px;
    font-weight: 700;
    @include mq(sp) {
      font-size: 20px;
      line-height: 1.3;
    }
  }

  &_box02 {
    @include flex(nowrap,space-between);
    @include base(1140,20,5vw);
    padding-top: 10px;
    &_detail {
      padding-top: 46px;
      @include mq(sp) {
        padding-top: 30px;
      }
    }
  }
  &_text03 {
    font-size: 30px;
    font-weight: 700;
    @include mq(sp) {
      margin-bottom: 10px;
      font-size: 20px;
      line-height: 1.3;
    }
    strong {
      color: $blue;
    }
  }
  &_image02 {
    width: 430px;
    @include mq(sp) {
      width: 80vw;
      margin-bottom: 10px;
    }
  }
  &_text04 {
    font-size: 20px;
    @include mq(sp) {
      font-size: 13px;
      line-height: 1.5;
    }
    strong {
      color: $pink;
      font-size: 22px;
      @include mq(sp) {
        font-size: 14px;
      }
    }
  }
  &_image03 {
    text-align: center;
    img {
      width: 900px;
      @include mq(sp) {
        width: 90vw;
      }
    }
  }
}

// learn
.block03 {
  margin-top: 60px;
  background: url(/img/top/bg_block03_01.png) center 50px no-repeat;
  background-size: cover;
  @include mq(sp) {
    margin-top: 30px;
    background-position: center 25px;
  }
  &_phase01 {
    @include base(1140,20,5vw);
    padding-bottom: 30px;
  }
  &_icon {
    text-align: center;
    figure {
      display: inline-block;
      width: 100px;
      height: 100px;
      @include mq(sp) {
        width: 50px;
        height: 50px;
      }
      figcaption {
        color: $blue;
        font-weight: 600;
        @include mq(sp) {
          font-size: 14px;
        }
      }
    }
  }
  &_title {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    @include mq(sp) {
      font-size: 15px;
    }
  }
  &_text01 {
    font-size: 46px;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    @include mq(sp) {
      margin-bottom: 10px;
      font-size: 25px;
    }
    strong {
      display: inline;
      border-bottom: 4px solid $pink;
      color: $pink;
      @include mq(sp) {
        border-width: 2px;
      }
    }
  }
  &_text02 {
    margin-bottom: 30px;
    font-size: 40px;
    color: $blue;
    font-weight: 700;
    letter-spacing: .1em;
    text-align: center;
    @include mq(sp) {
      margin-bottom: 20px;
      font-size: 20px;
    }
  }

  &_box01 {
    @include flex(nowrap,space-between);
    margin-bottom: 40px;
    padding: 20px 30px 30px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 5px 20px rgba(105, 192, 207, 0.4);
    @include mq(sp) {
      padding: 20px 20px 30px;
      border: 5px;
    }
  }
  &_graph {
    img {
      width: 470px;
      @include mq(sp) {
        width: auto;
      }
    }
  }
  &_caption {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    @include mq(sp) {
      font-size: 15px;
    }
  }
  &_detail {
    width: 500px;
    padding-top: 30px;
    @include mq(sp) {
      width: auto;
    }
    &_text {
      margin-bottom: 60px;
      font-size: 24px;
      @include mq(sp) {
        margin-bottom: 20px;
        font-size: 15px;
        line-height: 1.8;
      }
    }
  }
  &_supple {
    font-size: 16px;
    line-height: 1.8;
    @include mq(sp) {
      font-size: 12px;
    }
    span {
      vertical-align: middle;
      img {
        width: 62px;
        margin-right: 10px;
        vertical-align: middle;
        @include mq(sp) {
          width: 31px;
        }
      }
    }
  }
  &_note {
    padding-top: 10px;
    font-size: 12px;
    @include mq(sp) {
      line-height: 1.5;
    }
  }
  &_text03 {
    font-size: 30px;
    text-align: center;
    @include mq(sp) {
      font-size: 15px;
    }
  }
  &_text04 {
    font-size: 36px;
    font-weight: 700;
    line-height: 1.6;
    text-align: center;
    @include mq(sp) {
      font-size: 18px;
    }
    span {
      color: $blue;
    }
  }
  &_phase02 {
    padding-top: 80px;
    padding-bottom: 100px;
    background: url(/img/top/bg_block03_02.png) center top no-repeat;
    background-size: cover;
    @include mq(sp) {
      padding-top: 40px;
      padding-bottom: 50px;
    }
  }
  &_text05 {
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    @include mq(sp) {
      font-size: 16px;
    }
    strong {
      color: $yellow;
    }
  }
  &_text06 {
    margin-bottom: 50px;
    color: #fff;
    font-size: 30px;
    text-align: center;
    @include mq(sp) {
      margin-bottom: 30px;
      font-size: 15px;
    }
  }
  &_box02 {
    position: relative;
    width: 900px;
    margin: 0 auto;
    padding: 25px 0 25px 240px;
    border-radius: 10px;
    background: $green;
    color: #fff;
    @include mq(sp) {
      width: 90vw;
      margin-left: 5vw;
      margin-right: 5vw;
      padding: 15px 0 15px 75px;
    }
    &_icn {
      position: absolute;
      top: -20px;
      left: 40px;
      width: 150px;
      @include mq(sp) {
        top: -10px;
        left: -10px;
        width: 75px;
      }
    }
    &_text01 {
      font-size: 26px;
      line-height: 1.5;
      @include mq(sp) {
        font-size: 13px;
      }
    }
    &_text02 {
      font-size: 30px;
      font-weight: 700;
      line-height: 1.5;
      @include mq(sp) {
        font-size: 15px;
      }
    }
    &_text03 {
      font-size: 20px;
      line-height: 1.5;
      @include mq(sp) {
        font-size: 12px;
      }
    }
  }
}

// profile
.block04 {
  margin-top: 60px;
  margin-bottom: 80px;
  @include mq(sp) {
    margin-top: 30px;
    margin-bottom: 40px;
  }
  &_phase01 {
    @include base(1140,20,5vw);
  }
  &_icon {
    text-align: center;
    figure {
      display: inline-block;
      width: 100px;
      height: 100px;
      @include mq(sp) {
        width: 50px;
        height: 50px;
      }
      figcaption {
        color: $blue;
        font-weight: 600;
        @include mq(sp) {
          font-size: 14px;
        }
      }
    }
  }
  &_title {
    margin-bottom: 40px;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    @include mq(sp) {
      margin-bottom: 20px;
      font-size: 15px;
    }
  }
  &_box01 {
    @include flex(nowrap,space-between);
    margin-bottom: 80px;
    @include mq(sp) {
      margin-bottom: 40px;
    }
  }
  &_graph {
    width: 30%;
    @include mq(sp) {
      width: auto;
    }
  }
  &_caption {
    margin-bottom: 20px;
    background: $blue;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    @include mq(sp) {
      width: 70vw;
      margin: 0 auto 15px;
      padding: 2px 0;
      font-size: 15px;
      font-weight: 500;
    }
  }
  &_image01, &_image02, &_image03 {
    margin-bottom: 30px;
    text-align: center;
    img {
      width: 66vw;
    }
  }
  &_phase02 {
  }
  &_box02 {
    @include flex-sp(nowrap);
    @include mq(sp) {
      flex-wrap: wrap;
    }
  }
  &_image04 {
    width: 50%;
    &:last-child {
      flex-grow: 2;
    }
  }
  &_phase03 {
    @include base(1140,20,0);
    position: relative;
    z-index: 10;
    ul {
      margin-top: -50px;
      padding: 40px 160px;
      background: rgba(255,231,106,.7);
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
      @include mq(sp) {
        padding: 20px 5vw 50px;
      }
      li {
        margin-bottom: 5px;
        padding-left: 50px;
        background: url(/img/top/icn_check.svg) left center no-repeat;
        background-size: 33px 33px;
        font-size: 24px;
        font-weight: 700;
        @include mq(sp) {
          margin-bottom: 10px;
          padding-left: 30px;
          background-position: left 2px;
          background-size: 20px 20px;
          font-size: 14px;
          line-height: 1.6;
        }
      }
    }
  }
  &_text01 {
    position: absolute;
    bottom: 46px;
    right: 100px;
    font-size: 20px;
    font-weight: 500;
    @include mq(sp) {
      bottom: 20px;
      left: 5vw;
      right: auto;
      font-size: 12px;
    }
  }
}

// information
.block05 {
  padding: 70px 0 80px;
  background: $light-gray;
  @include mq(sp) {
    padding: 30px 0;
  }
  &_inner {
    @include base(1140,20,0);
  }
  &_text01 {
    margin-bottom: 30px;
    color: $blue;
    font-size: 36px;
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
    @include mq(sp) {
      margin-bottom: 20px;
      font-size: 17px;
    }
  }
  table {
    width: 960px;
    margin: 0 auto;
    border-top: 1px solid #BCBCC4;
    @include mq(sp) {
      width: 100vw;
    }
    tr {
      th {
        padding: 15px;
        border-bottom: 1px solid #BCBCC4;
        font-size: 18px;
        font-weight: 500;
        @include mq(sp) {
          display: block;
          width: 100%;
          padding-top: 10px;
          padding-bottom: 0;
          border-bottom: 0;
          font-size: 14px;
          font-weight: 700;
          line-height: 1.6;
        }
      }
      td {
        padding: 15px;
        border-bottom: 1px solid #BCBCC4;
        font-size: 18px;
        @include mq(sp) {
          display: block;
          width: 100%;
          padding-top: 0;
          padding-bottom: 10px;
          font-size: 18px;
          line-height: 1.6;
          text-align: center;
        }
      }
    }
  }
}

// award
.block06 {
  @include base(1140,20,5vw);
  margin-top: 60px;
  margin-bottom: 80px;
  @include mq(sp) {
    margin-top: 30px;
    margin-bottom: 40px;
  }
  &_text01 {
    margin-bottom: 50px;
    font-size: 36px;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    @include mq(sp) {
      margin-bottom: 20px;
      font-size: 16px;
    }
    strong {
      display: inline;
      border-bottom: 4px solid $pink;
      color: $pink;
      font-weight: 500;
      @include mq(sp) {
        border-width: 2px;
      }
    }
  }
  &_box01 {
    @include flex(nowrap,space-between);
    width: 920px;
    margin: 0 auto 50px;
    @include mq(sp) {
      width: auto;
      margin-bottom: 30px;
    }
  }
  &_image01, &_image02 {
    width: 47%;
    @include mq(sp) {
      width: 65vw;
      margin: 0 auto 20px;
    }
  }
  &_text02 {
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 1.5;
    text-align: center;
    @include mq(sp) {
      font-size: 12px;
    }
  }
  &_text03 {
    font-size: 20px;
    text-align: center;
    @include mq(sp) {
      font-size: 14px;
    }
  }
}

// about IFA
.block07 {
  &_phase01 {
    @include flex-sp(nowrap);
    @include mq(sp) {
      flex-wrap: wrap;
    }
  }
  &_image01 {
    width: 50%;
    &:last-child {
      flex-grow: 2;
    }
  }
  &_phase02 {
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    background: #DFF4BE;
    &::before {
      position: absolute;
      top: -50px;
      left: 0;
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 50px 0 0 100vw;
      border-color: transparent transparent transparent #DFF4BE;
      @include mq(sp) {
        top: -25px;
        border-width: 25px 0 0 100vw;
      }
    }
    &::after {
      position: absolute;
      bottom: -50px;
      left: 0;
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 100vw 50px 0;
      border-color: transparent #DFF4BE transparent transparent;
      @include mq(sp) {
        bottom: -25px;
        border-width: 0 100vw 25px 0;
      }
    }
    &_inner {
      @include base(1140,20,5vw);
      @include flex();
    }
    &_title {
      width: 20%;
      font-size: 36px;
      font-weight: 500;
      @include mq(sp) {
        width: 100%;
        font-size: 20px;
      }
    }
    &_text {
      width: 80%;
      font-size: 20px;
      @include mq(sp) {
        width: 100%;
        font-size: 16px;
        line-height: 1.8;
      }
    }
  }
  &_phase03 {
    padding-top: 80px;
    padding-bottom: 80px;
    background: $light-green;
    @include mq(sp) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    &_inner {
      @include base(1140,20,5vw);
    }
  }
  &_title {
    margin-bottom: 10px;
    font-size: 36px;
    font-weight: 500;
    text-align: center;
    @include mq(sp) {
      font-size: 20px;
    }
  }
  &_box01 {
    margin-bottom: 80px;
    @include mq(sp) {
      margin-bottom: 40px;
    }
    ol {
      @include flex(nowrap,space-between);
      li {
        width: 30%;
        list-style: none;
        @include mq(sp) {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }
  &_icon {
    margin-bottom: 20px;
    text-align: center;
    img {
      width: 260px;
      @include mq(sp) {
        width: 170px;
      }
    }
  }
  &_text01 {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.6;
    text-align: center;
    @include mq(sp) {
      font-size: 20px;
    }
  }
  &_text02 {
    color: $green;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.8;
    text-align: center;
    @include mq(sp) {
      font-size: 18px;
      line-height: 1.6;
    }
  }
}

// voice
.block08 {
  margin-top: 60px;
  margin-bottom: 80px;
  @include mq(sp) {
    margin-top: 30px;
    margin-bottom: 40px;
  }
  @include base(1140,20,5vw);
  &_icon {
    text-align: center;
    figure {
      display: inline-block;
      width: 100px;
      height: 100px;
      @include mq(sp) {
        width: 50px;
        height: 50px;
      }
      figcaption {
        color: $blue;
        font-weight: 600;
        @include mq(sp) {
          font-size: 14px;
        }
      }
    }
  }
  &_title {
    margin-bottom: 50px;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    @include mq(sp) {
      margin-bottom: 25px;
      font-size: 15px;
    }
  }
  &_voice {
    @include flex-sp(nowrap,space-between);
    margin-bottom: 40px;
    &_image {
      width: 140px;
      @include mq(sp) {
        width: 70px;
      }
    }
    &_text {
      position: relative;
      width: calc(100% - 200px);
      padding: 30px 40px;
      border-radius: 10px;
      background: $light-gray;
      @include mq(sp) {
        width: calc(100% - 90px);
        padding: 15px;
        line-height: 1.8;
      }
      &::before {
        position: absolute;
        top: 50%;
        left: -30px;
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 32px 46px;
        border-color: transparent transparent $light-gray transparent;
        @include mq(sp) {
          top: 30px;
          left: -18px;
          border-width: 0 0 16px 23px;
        }
      }
    }
  }
}

// faq
.block09 {
  padding: 80px 0;
  background: #47C2D1;
  @include mq(sp) {
    padding: 30px 0;
  }
  &_inner {
    @include base(1100,0,0);
  }
  &_title {
    margin-bottom: 40px;
    color: #fff;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    @include mq(sp) {
      margin-bottom: 20px;
      font-size: 20px;
    }
    span {
      display: inline-block;
      border-bottom: 4px solid #fff;
      @include mq(sp) {
        border-width: 2px;
      }
    }
  }
  &_box {
    padding: 80px 90px;
    background: #fff;
    @include mq(sp) {
      margin-left: 5vw;
      margin-right: 5vw;
      padding: 20px;
    }
    dl {
      @include mq(sp) {
        line-height: 1.6;
      }
      dt {
        position: relative;
        padding-left: 60px;
        margin-bottom: 20px;
        color: $blue;
        font-weight: 700;
        line-height: 1.8;
        @include mq(sp) {
          margin-bottom: 10px;
          padding-left: 30px;
        }
        &::before {
          position: absolute;
          left: 0;
          top: 2px;
          content: '';
          display: block;
          width: 29px;
          height: 30px;
          background: url(/img/top/icn_q.svg) 0 0 no-repeat;
          background-size: 29px 30px;
          @include mq(sp) {
            top: 5px;
            width: 18px;
            height: 18px;
            background-size: 18px 18px;
          }
        }
      }
      dd {
        position: relative;
        margin-bottom: 40px;
        padding-left: 60px;
        line-height: 1.8;
        @include mq(sp) {
          margin-bottom: 20px;
          padding-left: 30px;
        }
        &::before {
          position: absolute;
          left: 0;
          top: 2px;
          content: '';
          display: block;
          width: 31px;
          height: 29px;
          background: url(/img/top/icn_a.svg) 0 0 no-repeat;
          background-size: 31px 29px;
          @include mq(sp) {
            top: 5px;
            width: 18px;
            height: 18px;
            background-size: 18px 18px;
          }
        }
      }
    }
  }
}

// benefit
.block10 {
  @include base(1140,20,5vw);
  margin-top: 60px;
  margin-bottom: 80px;
  @include mq(sp) {
    margin-top: 30px;
    margin-bottom: 40px;
  }
  &_title {
    width: 530px;
    height: 87px;
    margin: 0 auto 50px;
    padding-top: 6px;
    background: url(/img/top/bg_block09_ttl.png) center top no-repeat;
    background-size: 530px auto;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    @include mq(sp) {
      width: 270px;
      height: 43px;
      padding-top: 0;
      background-size: 270px auto;
      font-size: 18px;
    }
  }
  &_box01 {
    @include flex(nowrap,space-between);
    min-width: 1100px;
    position: relative;
    margin-bottom: 65px;
    padding: 30px;
    border-radius: 10px;
    background: $light-pink;
    @include mq(sp) {
      min-width: auto;
      margin-bottom: 40px;
      padding: 15px 5vw;
      border-radius: 6px;
    }
    &::after {
      position: absolute;
      bottom: -50px;
      left: 50%;
      content: '';
      display: block;
      width: 40px;
      height: 40px;
      background: url(/img/top/icn_plus.svg) 0 0 no-repeat;
      background-size: 40px 40px;
      transform: translateX(-50%);
      @include mq(sp) {
        bottom: -30px;
        width: 20px;
        height: 20px;
        background-size: 20px 20px;
      }
    }
    &_icon {
      width: 150px;
      @include mq(sp) {
        width: 75px;
        margin: 0 auto;
      }
    }
    &_detail {
      position: relative;
      width: calc(100% - 180px);
      padding-top: 20px;
      @include mq(sp) {
        width: 100%;
        padding-top: 10px;
      }
    }
  }
  &_text01 {
    color: #fff;
    font-size: 26px;
    font-weight: 700;
    line-height: 1.5;
    @include mq(sp) {
      margin-bottom: 5px;
      font-size: 14px;
    }
    span {
      display: inline-block;
      padding: 2px 30px 5px;
      border-radius: 6px;
      background: $pink;
      @include mq(sp) {
        padding: 4px 15px;
      }
    }
  }
  &_text02 {
    margin-bottom: 10px;
    font-size: 36px;
    font-weight: 700;
    line-height: 1.8;
    @include mq(sp) {
      font-size: 18px;
    }
    span {
      display: inline;
      border-bottom: 4px solid $pink;
      @include mq(sp) {
        border-width: 2px;
      }
    }
  }
  &_text03 {
    font-size: 26px;
    font-weight: 700;
    @include mq(sp) {
      font-size: 15px;
      line-height: 1.6;
    }
  }
  &_text04 {
    font-size: 20px;
    font-weight: 500;
    @include mq(sp) {
      font-size: 14px;
      line-height: 1.6;
    }
    span {
      color: $pink;
      font-weight: 700;
    }
  }
  &_image01 {
    position: absolute;
    right: 0;
    bottom: 20px;
    width: 300px;
    @include mq(sp) {
      position: static;
      width: 70vw;
      margin: 10px auto;
    }
  }
  &_image02 {
    position: absolute;
    top: 10px;
    right: 0;
    width: 245px;
    @include mq(sp) {
      position: static;
      width: 40vw;
      margin: 10px auto;
    }
  }
  &_box02 {
    @include flex(nowrap,space-between);
    position: relative;
    margin-bottom: 65px;
    padding: 30px;
    border-radius: 10px;
    background: $light-green;
    @include mq(sp) {
      margin-bottom: 40px;
      padding: 15px 5vw;
      border-radius: 6px;
    }
    &_icon {
      width: 150px;
      @include mq(sp) {
        width: 75px;
        margin: 0 auto;
      }
    }
    &_detail {
      position: relative;
      width: calc(100% - 180px);
      padding-top: 20px;
      width: 100%;
      padding-top: 10px;
    }
  }
  &_text05 {
    color: #fff;
    font-size: 26px;
    font-weight: 700;
    line-height: 1.5;
    @include mq(sp) {
      font-size: 14px;
    }
    span {
      display: inline-block;
      padding: 2px 30px 5px;
      border-radius: 6px;
      background: $green;
      @include mq(sp) {
        padding: 4px 15px;
      }
    }
  }
  &_text06 {
    margin-bottom: 10px;
    font-size: 36px;
    font-weight: 700;
    line-height: 1.8;
    @include mq(sp) {
      font-size: 18px;
    }
    span {
      display: inline;
      border-bottom: 4px solid $green;
      @include mq(sp) {
        border-width: 2px;
      }
    }
  }
  &_text07 {
    font-size: 20px;
    font-weight: 500;
    @include mq(sp) {
      font-size: 14px;
      line-height: 1.6;
    }
  }
}

.block11 {
  @include base(1140,20,5vw);
  margin-top: 60px;
  margin-bottom: 80px;
  @include mq(sp) {
    margin-top: 30px;
    margin-bottom: 40px;
  }
  &_part {
    @include base(1140,20,5vw);
    margin-top: 60px;
    @include mq(sp) {
      margin-top: 30px;
    }
  }
  &_icon {
    text-align: center;
    figure {
      display: inline-block;
      width: 100px;
      height: 100px;
      @include mq(sp) {
        width: 50px;
        height: 50px;
      }
      figcaption {
        color: $blue;
        font-weight: 600;
        @include mq(sp) {
          font-size: 14px;
        }
      }
    }
  }
  &_title {
    margin-bottom: 70px;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    @include mq(sp) {
      margin-bottom: 30px;
      font-size: 15px;
    }
  }
  &_list {
    li {
      margin-bottom: 50px;
      padding-bottom: 50px;
      border-bottom: 1px solid #BCBCC4;
      @include mq(sp) {
        margin-bottom: 25px;
        padding-bottom: 25px;
      }
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
      }
    }
    &_box {
      @include flex(nowrap,space-between);
      &_image {
        width: 33%;
        @include mq(sp) {
          width: 80vw;
          margin: 0 auto 20px;
        }
      }
      &_detail {
        width: 60%;
        @include mq(sp) {
          width: 100%;
        }
        &_title {
          font-size: 20px;
          font-weight: 700;
          @include mq(sp) {
            margin-bottom: 10px;
            font-size: 16px;
            line-height: 1.5;
          }
        }
        table {
          th {
            width: 90px;
            padding: 5px 0;
            font-size: 16px;
            font-weight: 500;
            text-align: left;
            vertical-align: top;
            white-space: nowrap;
            @include mq(sp) {
              line-height: 1.6;
            }
          }
          td {
            width: calc(100% - 90px);
            padding: 5px 0;
            font-size: 16px;
            @include mq(sp) {
              line-height: 1.6;
            }
          }
        }
      }
    }
  }
  &_button {
    padding-top: 50px;
    text-align: center;
    @include mq(sp) {
      padding-top: 16px;
    }
    a {
      display: inline-block;
      width: 420px;
      padding: 18px 0;
      border: 2px solid #fff;
      border-radius: 100px;
      background: url(/img/common/icn_btn_wh.svg) 40px center no-repeat, $grad1;
      color: #fff;
      font-size: 24px;
      font-weight: 900;
      text-decoration: none;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
      @include mq(sp) {
        width: 90vw;
        padding: 13px 0;
        font-size: 20px;
        background-position: 30px center, 0 0;
        background-size: 12px 16px, auto;
      }
      span {
        color: #fff;
        transition: .5s;
      }
      &:hover {
        @include mq(pc) {
          background: url(/img/common/icn_btn_red.svg) 40px center no-repeat, $grad2;
          color: $pink;
        }
        span {
          @include mq(pc) {
            color: $pink;
          }
        }
      }
    }
  }
}

.block12 {
  background: $light-gray;
  &_inner {
    @include base(1140,20,5vw);
    padding-top: 60px;
    padding-bottom: 60px;
    font-size: 16px;
  }
  &_text01 {
    margin-bottom: 20px;
    font-weight: 700;
    @include mq(sp) {
      line-height: 1.6;
    }
  }
  &_text02 {
    margin-bottom: 20px;
  }
  a {
    color: $blue;
    text-decoration: underline;
    &:hover {
      @include mq(pc) {
        color: $gray;
      }
    }
  }
}

.covid19 {
  @include base(1140,20,5vw);
  margin-top: 60px;
  margin-bottom: 60px;
  @include mq(sp) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  &_inner {
    padding: 30px 68px 35px;
    border: 4px solid $blue;
    @include mq(sp) {
      padding: 15px 20px;
      border-width: 2px;
    }
  }
  &_text {
    margin-bottom: 30px;
    color: $blue;
    font-size: 30px;
    letter-spacing: 0;
    line-height: 1.5;
    @include mq(sp) {
      font-size: 15px;
      text-align: center;
    }
  }
  &_list {
    @include flex-sp(nowrap,space-between);
    @include mq(sp) {
      flex-wrap: wrap;
      justify-content: center;
    }
    li {
      width: 17.5%;
      @include mq(sp) {
        width: 22.4vw;
        margin-right: 4vw;
        margin-bottom: 10px;
      }
      &:nth-child(3), &:nth-child(5) {
        margin-right: 0;
      }
      figure {
        img {
          margin-bottom: 15px;
          @include mq(sp) {
            margin-bottom: 5px;
          }
        }
        figcaption {
          color: #707070;
          font-size: 20px;
          font-weight: 500;
          line-height: 1.3;
          text-align: center;
          @include mq(sp) {
            font-size: 12px;
          }
        }
      }
    }
  }
}