@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
/* Regular 400, Bold 700 */
/* Medium 500 */
/*!
 *
 * BurgerEditor StyleSheet Style Sample v2.21.0
 *
 */
@font-face {
  font-family: FontAwesome;
  src: url(../fonts/fontawesome-webfont.eot?v=4.6.3);
  src: url(../fonts/fontawesome-webfont.eot?#iefix&v=4.6.3) format("embedded-opentype"), url(../fonts/fontawesome-webfont.woff2?v=4.6.3) format("woff2"), url(../fonts/fontawesome-webfont.woff?v=4.6.3) format("woff"), url(../fonts/fontawesome-webfont.ttf?v=4.6.3) format("truetype"), url(../fonts/fontawesome-webfont.svg?v=4.6.3#fontawesomeregular) format("svg");
  font-weight: 400;
  font-style: normal;
}

[data-bgb] blockquote {
  position: relative;
  margin: 1em 0;
  padding: 1px 2.5em;
  border-radius: 5px;
  background: none;
  border: 2px solid #ccc;
  overflow: hidden;
  color: #888;
  font-size: .9em;
}

[data-bgb] blockquote:first-child {
  margin-top: 0;
}

[data-bgb] blockquote:before {
  content: "“";
  top: .3em;
  left: .25em;
}

[data-bgb] blockquote:after, [data-bgb] blockquote:before {
  display: block;
  font-family: serif;
  position: absolute;
  z-index: 0;
  color: #ccc;
  font-size: 3em;
}

[data-bgb] blockquote:after {
  content: "”";
  bottom: -.3em;
  right: .25em;
}

[data-bgb] u {
  text-decoration: underline;
}

[data-bgb] s, [data-bgb] u {
  font-weight: 400;
  font-style: normal;
}

[data-bgb] s {
  text-decoration: line-through;
}

[data-bgb] sub {
  vertical-align: sub;
}

[data-bgb] sub, [data-bgb] sup {
  font-size: .8em;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
}

[data-bgb] sup {
  vertical-align: super;
}

[data-bgb] hr {
  width: 100%;
  height: 1px;
  color: #BCBCC4;
  background: currentColor;
  border-style: none;
  margin: 50px auto;
}

[data-bgb] hr:first-child {
  margin-top: 0;
}

.bgt-box__caption {
  text-align: left;
  font-weight: 400;
  padding: 3px;
  background: none;
  font-size: .8em;
}

.bgt-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  vertical-align: middle;
  padding: 12px 10px;
  border-width: 1px;
  border-style: solid;
  background: #fff;
  text-decoration: none;
  min-width: 280px;
  border-radius: 2px;
}

.bgt-btn:active, .bgt-btn:focus {
  -webkit-box-shadow: 0 0 10px 0 #0570c7, 0 0 10px 0 #fff;
  box-shadow: 0 0 10px 0 #0570c7, 0 0 10px 0 #fff;
}

.bgt-btn--link, [data-bgt-button-kind=link] .bgt-btn {
  color: #333;
  border-color: #ccc;
  background: #fff;
}

.bgt-btn--link:active, .bgt-btn--link:focus, .bgt-btn--link:hover, [data-bgt-button-kind=link] .bgt-btn:active, [data-bgt-button-kind=link] .bgt-btn:focus, [data-bgt-button-kind=link] .bgt-btn:hover {
  background: #e9e9e9;
}

.bgt-btn--em, [data-bgt-button-kind=em] .bgt-btn {
  font-weight: 700;
  color: #fff;
  border-color: #333;
  background: #333;
}

.bgt-btn--em:active, .bgt-btn--em:focus, .bgt-btn--em:hover, [data-bgt-button-kind=em] .bgt-btn:active, [data-bgt-button-kind=em] .bgt-btn:focus, [data-bgt-button-kind=em] .bgt-btn:hover {
  background: #555;
}

.bgt-btn--external, [data-bgt-button-kind=external] .bgt-btn {
  color: #333;
  border-color: #ccc;
  background: #fff;
}

.bgt-btn--external:active, .bgt-btn--external:focus, .bgt-btn--external:hover, [data-bgt-button-kind=external] .bgt-btn:active, [data-bgt-button-kind=external] .bgt-btn:focus, [data-bgt-button-kind=external] .bgt-btn:hover {
  background: #e9e9e9;
}

.bgt-btn--external:after, [data-bgt-button-kind=external] .bgt-btn:after {
  font-family: FontAwesome, serif;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f08e";
  font-size: 1em;
  color: #999;
  vertical-align: middle;
}

.bgt-btn--back, [data-bgt-button-kind=back] .bgt-btn {
  color: #333;
  border-color: #ddd;
  background: #ddd;
}

.bgt-btn--back:active, .bgt-btn--back:focus, .bgt-btn--back:hover, [data-bgt-button-kind=back] .bgt-btn:active, [data-bgt-button-kind=back] .bgt-btn:focus, [data-bgt-button-kind=back] .bgt-btn:hover {
  background: #e9e9e9;
}

.bgt-btn--back span, [data-bgt-button-kind=back] .bgt-btn span {
  display: inline-block;
  vertical-align: middle;
}

.bgt-btn--back:before, [data-bgt-button-kind=back] .bgt-btn:before {
  font-family: FontAwesome, serif;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f053";
  font-size: 1em;
  margin-top: -2px;
  display: inline-block;
  vertical-align: middle;
}

.bgt-hr {
  margin: 0 auto;
}

[data-bgt-hr-kind=dashed] .bgt-hr {
  height: 0;
  background: none;
  border: none;
  border-bottom: 2px dashed;
}

.bgt-hr--bold, [data-bgt-hr-kind=bold] .bgt-hr {
  height: 5px;
}

.bgt-hr--narrow, [data-bgt-hr-kind=narrow] .bgt-hr {
  height: 1px;
}

.bgt-hr--short, [data-bgt-hr-kind=short] .bgt-hr {
  width: 60%;
  height: 1px;
}

.bgt-download-file__link {
  color: #333;
  text-align: left;
  font-size: 1.08em;
  font-weight: 700;
  display: inline-block;
  padding: .7em 1em;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fff;
  line-height: 1;
  text-decoration: none;
}

.bgt-download-file__link:hover {
  background-color: #eee;
}

.bgt-download-file__link:active, .bgt-download-file__link:focus {
  -webkit-box-shadow: 0 0 5px 1px #0570c7;
  box-shadow: 0 0 5px 1px #0570c7;
  outline: none;
}

.bgt-link__size {
  color: grey;
  font-size: .8em;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .bgt-google-maps-link {
    margin-top: 10px;
    padding: 1em;
    text-decoration: none;
    color: #333;
    border: 1px solid #999;
    border-radius: 3px;
    text-align: center;
  }
  .bgt-google-maps-link span {
    display: inline-block;
    vertical-align: middle;
  }
  .bgt-google-maps-link:after {
    font-family: FontAwesome, serif;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f08e";
    font-size: 1em;
    vertical-align: middle;
  }
}

[data-bgt=gallery] .bgt-gallery {
  padding-top: 54%;
}

[data-bgt=gallery] .bgt-gallery:hover [data-bgt=gallery] .bgt-gallery-ctrl {
  opacity: 1;
}

[data-bgt=gallery] .bgt-gallery__img img {
  -o-object-fit: cover;
  font-family: "object-fit:cover";
  object-fit: cover;
}

[data-bgt=gallery] .bgt-gallery__caption {
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  padding: .7em 1em;
  text-align: center;
}

[data-bgt=gallery] .bgt-gallery-ctrl {
  top: 50%;
  opacity: 0;
  -webkit-transition: opacity .2s ease-in;
  transition: opacity .2s ease-in;
}

[data-bgt=gallery] .bgt-gallery-ctrl__next, [data-bgt=gallery] .bgt-gallery-ctrl__prev {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: -20px 0 0;
  padding: 0;
  background: none;
  border: none;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

[data-bgt=gallery] .bgt-gallery-ctrl__next:hover, [data-bgt=gallery] .bgt-gallery-ctrl__prev:hover {
  opacity: .5;
}

[data-bgt=gallery] .bgt-gallery-ctrl__next:focus, [data-bgt=gallery] .bgt-gallery-ctrl__prev:focus {
  outline: none;
}

[data-bgt=gallery] .bgt-gallery-ctrl__next span, [data-bgt=gallery] .bgt-gallery-ctrl__prev span {
  display: block;
  overflow: hidden;
  width: 0;
  height: 0;
}

[data-bgt=gallery] .bgt-gallery-ctrl__next:after, [data-bgt=gallery] .bgt-gallery-ctrl__prev:after {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  border: solid #fff;
  border-width: 5px 5px 0 0;
}

[data-bgt=gallery] .bgt-gallery-ctrl__prev {
  left: 20px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

[data-bgt=gallery] .bgt-gallery-ctrl__next {
  right: 20px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

[data-bgt=gallery] [data-gallery-marker=thumbs] + .bgt-gallery-marker {
  margin-top: 10px;
}

[data-bgt=gallery] [data-gallery-marker=thumbs] + .bgt-gallery-marker li {
  margin: 0 5px;
  width: 60px;
  height: 60px;
}

[data-bgt=gallery] [data-gallery-marker=dot] + .bgt-gallery-marker {
  margin-top: 10px;
}

[data-bgt=gallery] [data-gallery-marker=dot] + .bgt-gallery-marker li {
  margin: 0 2px;
  width: 10px;
  height: 10px;
  background-color: #999;
  border-radius: 100%;
}

[data-bgt=gallery] [data-gallery-marker=dot] + .bgt-gallery-marker li.current {
  background-color: #000;
}

.pc-only {
  display: block;
}

@media screen and (max-width: 768px) {
  .pc-only {
    display: none;
  }
}

.sp-only {
  display: none;
}

@media screen and (max-width: 768px) {
  .sp-only {
    display: block;
  }
}

.bgt-box__image-container {
  text-align: center;
}

.bgt-box__caption {
  font-size: 18px;
  text-align: center;
}

.bge-contents [data-bgb=hr] {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=hr] {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

.bge-contents.bge_contents.bge_content .responsive_pc {
  position: relative;
  padding-bottom: 10px;
}

.bge-contents.bge_contents.bge_content .responsive_pc::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  z-index: 100;
  content: "　パソコン版　";
  width: auto;
  height: auto;
  background: #ff0;
  color: #000;
  font-size: 16px;
  line-height: 1.5;
}

.bge-contents.bge_contents.bge_content .responsive_sp {
  position: relative;
  text-align: center;
}

.bge-contents.bge_contents.bge_content .responsive_sp::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  z-index: 100;
  content: "　スマホ版　";
  width: auto;
  height: auto;
  background: #ff0;
  color: #000;
  font-size: 16px;
  line-height: 1.5;
}

.bge-contents.bge_contents.bge_content .responsive_sp img {
  width: 40%;
}

.wrapper .main .bge-contents .responsive_pc {
  display: block;
}

@media screen and (max-width: 760px) {
  .wrapper .main .bge-contents .responsive_pc {
    display: none;
  }
}

.wrapper .main .bge-contents .responsive_sp {
  display: none;
}

@media screen and (max-width: 760px) {
  .wrapper .main .bge-contents .responsive_sp {
    display: block;
  }
}

.flex-block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex-block .flex-grid {
  width: 48%;
}

@media screen and (max-width: 760px) {
  .flex-block .flex-grid {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 760px) {
  .flex-block .flex-grid:last-child {
    margin-bottom: 0;
  }
}

.flex-block .flex-grid .bgt-image-container {
  margin-bottom: 10px;
}

[data-bgb=wysiwyg3] {
  display: flex;
}

@media screen and (max-width: 760px) {
  [data-bgb=wysiwyg3] {
    display: block;
  }
}

[data-bgb=wysiwyg3] .bgt-grid {
  width: 32%;
  margin-right: 2%;
}

@media screen and (max-width: 760px) {
  [data-bgb=wysiwyg3] .bgt-grid {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

[data-bgb=wysiwyg3] .bgt-grid--last {
  margin-right: 0;
}

[data-bgb=button].red {
  background: #FFFAE3 url(/img/common/bg_btn.png) center no-repeat;
  background-size: 1355px auto;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

@media screen and (max-width: 760px) {
  [data-bgb=button].red {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

[data-bgb=button].red a {
  display: inline-block;
  width: 780px;
  padding: 24px 0;
  border: 2px solid #fff;
  border-radius: 100px;
  background: url(/img/common/icn_btn_wh.svg) 70px center no-repeat, linear-gradient(-90deg, #e62162 0%, #ed4988 100%);
  color: #fff;
  font-size: 32px;
  font-weight: 900;
  text-decoration: none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 760px) {
  [data-bgb=button].red a {
    width: 90vw;
    padding: 13px 0;
    font-size: 20px;
    background-position: 30px center, 0 0;
    background-size: 12px 16px, auto;
  }
}

[data-bgb=button].red a span.yellow {
  color: #FFE76A;
  transition: .5s;
}

@media screen and (min-width: 761px) {
  [data-bgb=button].red a:hover {
    background: url(/img/common/icn_btn_red.svg) 70px center no-repeat, linear-gradient(90deg, #ffef9f 0%, #fce67d 100%);
    color: #E8316E;
  }
}

@media screen and (min-width: 761px) {
  [data-bgb=button].red a:hover span.yellow {
    color: #E8316E;
  }
}

[data-bgb=button].green {
  background: #FFFAE3 url(/img/common/bg_btn.png) center no-repeat;
  background-size: 1355px auto;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

@media screen and (max-width: 760px) {
  [data-bgb=button].green {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

[data-bgb=button].green a {
  display: inline-block;
  width: 780px;
  padding: 24px 0;
  border: 2px solid #fff;
  border-radius: 100px;
  background: url(/img/common/icn_btn_wh.svg) 70px center no-repeat, linear-gradient(-90deg, #90d127 0%, #79bf07 100%);
  color: #fff;
  font-size: 32px;
  font-weight: 900;
  text-decoration: none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 760px) {
  [data-bgb=button].green a {
    width: 90vw;
    padding: 13px 0;
    font-size: 20px;
    background-position: 30px center, 0 0;
    background-size: 12px 16px, auto;
  }
}

[data-bgb=button].green a span.yellow {
  color: #fff;
  transition: .5s;
}

@media screen and (min-width: 761px) {
  [data-bgb=button].green a:hover {
    background: url(/img/common/icn_btn_orn.svg) 70px center no-repeat, linear-gradient(90deg, #ffef9f 0%, #fce67d 100%);
    color: #F6680F;
  }
}

@media screen and (min-width: 761px) {
  [data-bgb=button].green a:hover span.yellow {
    color: #F6680F;
  }
}

[data-bgb=button].orange {
  background: #FFFAE3 url(/img/common/bg_btn.png) center no-repeat;
  background-size: 1355px auto;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

@media screen and (max-width: 760px) {
  [data-bgb=button].orange {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

[data-bgb=button].orange a {
  display: inline-block;
  width: 780px;
  padding: 24px 0;
  border: 2px solid #fff;
  border-radius: 100px;
  background: url(/img/common/icn_btn_wh.svg) 70px center no-repeat, linear-gradient(-90deg, #f8aa2d 0%, #f69d0f 100%);
  color: #fff;
  font-size: 32px;
  font-weight: 900;
  text-decoration: none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 760px) {
  [data-bgb=button].orange a {
    width: 90vw;
    padding: 13px 0;
    font-size: 20px;
    background-position: 30px center, 0 0;
    background-size: 12px 16px, auto;
  }
}

[data-bgb=button].orange a span.yellow {
  color: #fff;
  transition: .5s;
}

@media screen and (min-width: 761px) {
  [data-bgb=button].orange a:hover {
    background: url(/img/common/icn_btn_orn.svg) 70px center no-repeat, linear-gradient(90deg, #ffef9f 0%, #fce67d 100%);
    color: #F6680F;
  }
}

@media screen and (min-width: 761px) {
  [data-bgb=button].orange a:hover span.yellow {
    color: #F6680F;
  }
}

[data-bgb=button].s-red {
  padding-top: 50px;
  padding-bottom: 80px;
  text-align: center;
}

@media screen and (max-width: 760px) {
  [data-bgb=button].s-red {
    padding-top: 16px;
    padding-bottom: 40px;
  }
}

[data-bgb=button].s-red a {
  display: inline-block;
  width: 420px;
  padding: 18px 0;
  border: 2px solid #fff;
  border-radius: 100px;
  background: url(/img/common/icn_btn_wh.svg) 40px center no-repeat, linear-gradient(-90deg, #e62162 0%, #ed4988 100%);
  color: #fff;
  font-size: 24px;
  font-weight: 900;
  text-decoration: none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 760px) {
  [data-bgb=button].s-red a {
    width: 90vw;
    padding: 13px 0;
    font-size: 20px;
    background-position: 30px center, 0 0;
    background-size: 12px 16px, auto;
  }
}

[data-bgb=button].s-red a span {
  color: #fff;
  transition: .5s;
}

@media screen and (min-width: 761px) {
  [data-bgb=button].s-red a:hover {
    background: url(/img/common/icn_btn_red.svg) 40px center no-repeat, linear-gradient(90deg, #ffef9f 0%, #fce67d 100%);
    color: #E8316E;
  }
}

@media screen and (min-width: 761px) {
  [data-bgb=button].s-red a:hover span {
    color: #E8316E;
  }
}

[data-bgb=button].s-green {
  padding-top: 50px;
  padding-bottom: 80px;
  text-align: center;
}

@media screen and (max-width: 760px) {
  [data-bgb=button].s-green {
    padding-top: 16px;
    padding-bottom: 40px;
  }
}

[data-bgb=button].s-green a {
  display: inline-block;
  width: 420px;
  padding: 18px 0;
  border: 2px solid #fff;
  border-radius: 100px;
  background: url(/img/common/icn_btn_wh.svg) 40px center no-repeat, linear-gradient(-90deg, #90d127 0%, #79bf07 100%);
  color: #fff;
  font-size: 24px;
  font-weight: 900;
  text-decoration: none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 760px) {
  [data-bgb=button].s-green a {
    width: 90vw;
    padding: 13px 0;
    font-size: 20px;
    background-position: 30px center, 0 0;
    background-size: 12px 16px, auto;
  }
}

[data-bgb=button].s-green a span {
  color: #fff;
  transition: .5s;
}

@media screen and (min-width: 761px) {
  [data-bgb=button].s-green a:hover {
    background: url(/img/common/icn_btn_orn.svg) 40px center no-repeat, linear-gradient(90deg, #ffef9f 0%, #fce67d 100%);
    color: #F6680F;
  }
}

@media screen and (min-width: 761px) {
  [data-bgb=button].s-green a:hover span {
    color: #F6680F;
  }
}

[data-bgb=button].s-orange {
  padding-top: 50px;
  padding-bottom: 80px;
  text-align: center;
}

@media screen and (max-width: 760px) {
  [data-bgb=button].s-orange {
    padding-top: 16px;
    padding-bottom: 40px;
  }
}

[data-bgb=button].s-orange a {
  display: inline-block;
  width: 420px;
  padding: 18px 0;
  border: 2px solid #fff;
  border-radius: 100px;
  background: url(/img/common/icn_btn_wh.svg) 40px center no-repeat, linear-gradient(-90deg, #f8aa2d 0%, #f69d0f 100%);
  color: #fff;
  font-size: 24px;
  font-weight: 900;
  text-decoration: none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 760px) {
  [data-bgb=button].s-orange a {
    width: 90vw;
    padding: 13px 0;
    font-size: 20px;
    background-position: 30px center, 0 0;
    background-size: 12px 16px, auto;
  }
}

[data-bgb=button].s-orange a span {
  color: #fff;
  transition: .5s;
}

@media screen and (min-width: 761px) {
  [data-bgb=button].s-orange a:hover {
    background: url(/img/common/icn_btn_orn.svg) 40px center no-repeat, linear-gradient(90deg, #ffef9f 0%, #fce67d 100%);
    color: #F6680F;
  }
}

@media screen and (min-width: 761px) {
  [data-bgb=button].s-orange a:hover span {
    color: #F6680F;
  }
}

[data-bgb=button-red] {
  background: #FFFAE3 url(/img/common/bg_btn.png) center no-repeat;
  background-size: 1355px auto;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

@media screen and (max-width: 760px) {
  [data-bgb=button-red] {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

[data-bgb=button-red] a.bgt-btn {
  display: inline-block;
  width: 780px;
  padding: 24px 0;
  border: 2px solid #fff;
  border-radius: 100px;
  background: url(/img/common/icn_btn_wh.svg) 70px center no-repeat, linear-gradient(-90deg, #e62162 0%, #ed4988 100%);
  color: #fff;
  font-size: 32px;
  font-weight: 900;
  text-decoration: none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 760px) {
  [data-bgb=button-red] a.bgt-btn {
    width: 90vw;
    padding: 13px 0;
    font-size: 20px;
    background-position: 30px center, 0 0;
    background-size: 12px 16px, auto;
  }
}

[data-bgb=button-red] a.bgt-btn span.yellow {
  color: #FFE76A;
  transition: .5s;
}

@media screen and (min-width: 761px) {
  [data-bgb=button-red] a.bgt-btn:hover {
    background: url(/img/common/icn_btn_red.svg) 70px center no-repeat, linear-gradient(90deg, #ffef9f 0%, #fce67d 100%);
    color: #E8316E;
  }
}

@media screen and (min-width: 761px) {
  [data-bgb=button-red] a.bgt-btn:hover span.yellow {
    color: #E8316E;
  }
}

[data-bgb=button-green] {
  background: #FFFAE3 url(/img/common/bg_btn.png) center no-repeat;
  background-size: 1355px auto;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

@media screen and (max-width: 760px) {
  [data-bgb=button-green] {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

[data-bgb=button-green] a.bgt-btn {
  display: inline-block;
  width: 780px;
  padding: 24px 0;
  border: 2px solid #fff;
  border-radius: 100px;
  background: url(/img/common/icn_btn_wh.svg) 70px center no-repeat, linear-gradient(-90deg, #90d127 0%, #79bf07 100%);
  color: #fff;
  font-size: 32px;
  font-weight: 900;
  text-decoration: none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 760px) {
  [data-bgb=button-green] a.bgt-btn {
    width: 90vw;
    padding: 13px 0;
    font-size: 20px;
    background-position: 30px center, 0 0;
    background-size: 12px 16px, auto;
  }
}

[data-bgb=button-green] a.bgt-btn span.yellow {
  color: #fff;
  transition: .5s;
}

@media screen and (min-width: 761px) {
  [data-bgb=button-green] a.bgt-btn:hover {
    background: url(/img/common/icn_btn_orn.svg) 70px center no-repeat, linear-gradient(90deg, #ffef9f 0%, #fce67d 100%);
    color: #F6680F;
  }
}

@media screen and (min-width: 761px) {
  [data-bgb=button-green] a.bgt-btn:hover span.yellow {
    color: #F6680F;
  }
}

[data-bgb=button-orange] {
  background: #FFFAE3 url(/img/common/bg_btn.png) center no-repeat;
  background-size: 1355px auto;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

@media screen and (max-width: 760px) {
  [data-bgb=button-orange] {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

[data-bgb=button-orange] a.bgt-btn {
  display: inline-block;
  width: 780px;
  padding: 24px 0;
  border: 2px solid #fff;
  border-radius: 100px;
  background: url(/img/common/icn_btn_wh.svg) 70px center no-repeat, linear-gradient(-90deg, #f8aa2d 0%, #f69d0f 100%);
  color: #fff;
  font-size: 32px;
  font-weight: 900;
  text-decoration: none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 760px) {
  [data-bgb=button-orange] a.bgt-btn {
    width: 90vw;
    padding: 13px 0;
    font-size: 20px;
    background-position: 30px center, 0 0;
    background-size: 12px 16px, auto;
  }
}

[data-bgb=button-orange] a.bgt-btn span.yellow {
  color: #fff;
  transition: .5s;
}

@media screen and (min-width: 761px) {
  [data-bgb=button-orange] a.bgt-btn:hover {
    background: url(/img/common/icn_btn_orn.svg) 70px center no-repeat, linear-gradient(90deg, #ffef9f 0%, #fce67d 100%);
    color: #F6680F;
  }
}

@media screen and (min-width: 761px) {
  [data-bgb=button-orange] a.bgt-btn:hover span.yellow {
    color: #F6680F;
  }
}

[data-bgb=button-s-red] {
  padding-top: 50px;
  padding-bottom: 80px;
  text-align: center;
}

@media screen and (max-width: 760px) {
  [data-bgb=button-s-red] {
    padding-top: 16px;
    padding-bottom: 40px;
  }
}

[data-bgb=button-s-red] a.bgt-btn {
  display: inline-block;
  width: 420px;
  padding: 18px 0;
  border: 2px solid #fff;
  border-radius: 100px;
  background: url(/img/common/icn_btn_wh.svg) 40px center no-repeat, linear-gradient(-90deg, #e62162 0%, #ed4988 100%);
  color: #fff;
  font-size: 24px;
  font-weight: 900;
  text-decoration: none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 760px) {
  [data-bgb=button-s-red] a.bgt-btn {
    width: 90vw;
    padding: 13px 0;
    font-size: 20px;
    background-position: 30px center, 0 0;
    background-size: 12px 16px, auto;
  }
}

[data-bgb=button-s-red] a.bgt-btn span {
  color: #fff;
  transition: .5s;
}

@media screen and (min-width: 761px) {
  [data-bgb=button-s-red] a.bgt-btn:hover {
    background: url(/img/common/icn_btn_red.svg) 40px center no-repeat, linear-gradient(90deg, #ffef9f 0%, #fce67d 100%);
    color: #E8316E;
  }
}

@media screen and (min-width: 761px) {
  [data-bgb=button-s-red] a.bgt-btn:hover span {
    color: #E8316E;
  }
}

[data-bgb=button-s-green] {
  padding-top: 50px;
  padding-bottom: 80px;
  text-align: center;
}

@media screen and (max-width: 760px) {
  [data-bgb=button-s-green] {
    padding-top: 16px;
    padding-bottom: 40px;
  }
}

[data-bgb=button-s-green] a.bgt-btn {
  display: inline-block;
  width: 420px;
  padding: 18px 0;
  border: 2px solid #fff;
  border-radius: 100px;
  background: url(/img/common/icn_btn_wh.svg) 40px center no-repeat, linear-gradient(-90deg, #90d127 0%, #79bf07 100%);
  color: #fff;
  font-size: 24px;
  font-weight: 900;
  text-decoration: none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 760px) {
  [data-bgb=button-s-green] a.bgt-btn {
    width: 90vw;
    padding: 13px 0;
    font-size: 20px;
    background-position: 30px center, 0 0;
    background-size: 12px 16px, auto;
  }
}

[data-bgb=button-s-green] a.bgt-btn span {
  color: #fff;
  transition: .5s;
}

@media screen and (min-width: 761px) {
  [data-bgb=button-s-green] a.bgt-btn:hover {
    background: url(/img/common/icn_btn_orn.svg) 40px center no-repeat, linear-gradient(90deg, #ffef9f 0%, #fce67d 100%);
    color: #F6680F;
  }
}

@media screen and (min-width: 761px) {
  [data-bgb=button-s-green] a.bgt-btn:hover span {
    color: #F6680F;
  }
}

[data-bgb=button-s-orange] {
  padding-top: 50px;
  padding-bottom: 80px;
  text-align: center;
}

@media screen and (max-width: 760px) {
  [data-bgb=button-s-orange] {
    padding-top: 16px;
    padding-bottom: 40px;
  }
}

[data-bgb=button-s-orange] a.bgt-btn {
  display: inline-block;
  width: 420px;
  padding: 18px 0;
  border: 2px solid #fff;
  border-radius: 100px;
  background: url(/img/common/icn_btn_wh.svg) 40px center no-repeat, linear-gradient(-90deg, #f8aa2d 0%, #f69d0f 100%);
  color: #fff;
  font-size: 24px;
  font-weight: 900;
  text-decoration: none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 760px) {
  [data-bgb=button-s-orange] a.bgt-btn {
    width: 90vw;
    padding: 13px 0;
    font-size: 20px;
    background-position: 30px center, 0 0;
    background-size: 12px 16px, auto;
  }
}

[data-bgb=button-s-orange] a.bgt-btn span {
  color: #fff;
  transition: .5s;
}

@media screen and (min-width: 761px) {
  [data-bgb=button-s-orange] a.bgt-btn:hover {
    background: url(/img/common/icn_btn_orn.svg) 40px center no-repeat, linear-gradient(90deg, #ffef9f 0%, #fce67d 100%);
    color: #F6680F;
  }
}

@media screen and (min-width: 761px) {
  [data-bgb=button-s-orange] a.bgt-btn:hover span {
    color: #F6680F;
  }
}

@media screen and (max-width: 760px) {
  [data-bgb].responsive table th {
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: 760px) {
  [data-bgb].responsive table td {
    display: block;
    width: 100%;
  }
}

[data-bgb=button2] .bgt-grid--last, [data-bgb=download-file2] .bgt-grid--last, [data-bgb=image2] .bgt-grid--last, [data-bgb=image-link2] .bgt-grid--last, [data-bgb=image-text2] .bgt-grid--last, [data-bgb=image-link-text2] .bgt-grid--last, [data-bgb=trimmed-image2] .bgt-grid--last, [data-bgb=trimmed-image-link2] .bgt-grid--last, [data-bgb=wysiwyg2] .bgt-grid--last {
  padding-left: 30px;
}

@media screen and (max-width: 760px) {
  [data-bgb=button2] .bgt-grid--last, [data-bgb=download-file2] .bgt-grid--last, [data-bgb=image2] .bgt-grid--last, [data-bgb=image-link2] .bgt-grid--last, [data-bgb=image-text2] .bgt-grid--last, [data-bgb=image-link-text2] .bgt-grid--last, [data-bgb=trimmed-image2] .bgt-grid--last, [data-bgb=trimmed-image-link2] .bgt-grid--last, [data-bgb=wysiwyg2] .bgt-grid--last {
    padding-left: 0;
  }
}

[data-bgb=button2] .bgt-grid--first, [data-bgb=download-file2] .bgt-grid--first, [data-bgb=image2] .bgt-grid--first, [data-bgb=image-link2] .bgt-grid--first, [data-bgb=image-text2] .bgt-grid--first, [data-bgb=image-link-text2] .bgt-grid--first, [data-bgb=trimmed-image2] .bgt-grid--first, [data-bgb=trimmed-image-link2] .bgt-grid--first, [data-bgb=wysiwyg2] .bgt-grid--first {
  padding-right: 30px;
}

@media screen and (max-width: 760px) {
  [data-bgb=button2] .bgt-grid--first, [data-bgb=download-file2] .bgt-grid--first, [data-bgb=image2] .bgt-grid--first, [data-bgb=image-link2] .bgt-grid--first, [data-bgb=image-text2] .bgt-grid--first, [data-bgb=image-link-text2] .bgt-grid--first, [data-bgb=trimmed-image2] .bgt-grid--first, [data-bgb=trimmed-image-link2] .bgt-grid--first, [data-bgb=wysiwyg2] .bgt-grid--first {
    padding-right: 0;
  }
}

[data-bgb=text-image2] .bgt-grid--first {
  padding-right: 20px;
}

@media screen and (max-width: 760px) {
  [data-bgb=text-image2] .bgt-grid--first {
    padding-right: 0;
  }
}

[data-bgb=text-image2] .bgt-grid--last {
  padding-left: 20px;
}

@media screen and (max-width: 760px) {
  [data-bgb=text-image2] .bgt-grid--last {
    padding-left: 0;
  }
}

[data-bgb=wysiwyg2] .bgt-grid [data-bgt] > :last-child {
  margin-bottom: 0 !important;
}

@media screen and (max-width: 760px) {
  [data-bgb] .bgt-grid {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 760px) {
  [data-bgb] .bgt-grid--last {
    margin-bottom: 0;
  }
}

.button, .button-red {
  background: #FFFAE3 url(/img/common/bg_btn.png) center no-repeat;
  background-size: 1355px auto;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .button, .button-red {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.button a, .button-red a {
  display: inline-block;
  width: 780px;
  padding: 24px 0;
  border: 2px solid #fff;
  border-radius: 100px;
  background: url(/img/common/icn_btn_wh.svg) 70px center no-repeat, linear-gradient(-90deg, #e62162 0%, #ed4988 100%);
  color: #fff;
  font-size: 32px;
  font-weight: 900;
  text-decoration: none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 760px) {
  .button a, .button-red a {
    width: 90vw;
    padding: 13px 0;
    font-size: 20px;
    background-position: 30px center, 0 0;
    background-size: 12px 16px, auto;
  }
}

.button a span, .button-red a span {
  color: #FFE76A;
  transition: .5s;
}

@media screen and (min-width: 761px) {
  .button a:hover, .button-red a:hover {
    background: url(/img/common/icn_btn_red.svg) 70px center no-repeat, linear-gradient(90deg, #ffef9f 0%, #fce67d 100%);
    color: #E8316E;
  }
}

@media screen and (min-width: 761px) {
  .button a:hover span, .button-red a:hover span {
    color: #E8316E;
  }
}

.button-green {
  background: #FFFAE3 url(/img/common/bg_btn.png) center no-repeat;
  background-size: 1355px auto;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .button-green {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.button-green a {
  display: inline-block;
  width: 780px;
  padding: 24px 0;
  border: 2px solid #fff;
  border-radius: 100px;
  background: url(/img/common/icn_btn_wh.svg) 70px center no-repeat, linear-gradient(-90deg, #90d127 0%, #79bf07 100%);
  color: #fff;
  font-size: 32px;
  font-weight: 900;
  text-decoration: none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 760px) {
  .button-green a {
    width: 90vw;
    padding: 13px 0;
    font-size: 20px;
    background-position: 30px center, 0 0;
    background-size: 12px 16px, auto;
  }
}

.button-green a span {
  color: #fff;
  transition: .5s;
}

@media screen and (min-width: 761px) {
  .button-green a:hover {
    background: url(/img/common/icn_btn_orn.svg) 70px center no-repeat, linear-gradient(90deg, #ffef9f 0%, #fce67d 100%);
    color: #F6680F;
  }
}

.button-orange {
  background: #FFFAE3 url(/img/common/bg_btn.png) center no-repeat;
  background-size: 1355px auto;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .button-orange {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.button-orange a {
  display: inline-block;
  width: 780px;
  padding: 24px 0;
  border: 2px solid #fff;
  border-radius: 100px;
  background: url(/img/common/icn_btn_wh.svg) 70px center no-repeat, linear-gradient(-90deg, #f8aa2d 0%, #f69d0f 100%);
  color: #fff;
  font-size: 32px;
  font-weight: 900;
  text-decoration: none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 760px) {
  .button-orange a {
    width: 90vw;
    padding: 13px 0;
    font-size: 20px;
    background-position: 30px center, 0 0;
    background-size: 12px 16px, auto;
  }
}

.button-orange a span {
  color: #fff;
  transition: .5s;
}

@media screen and (min-width: 761px) {
  .button-orange a:hover {
    background: url(/img/common/icn_btn_orn.svg) 70px center no-repeat, linear-gradient(90deg, #ffef9f 0%, #fce67d 100%);
    color: #F6680F;
  }
}

.button-s, .button-s-red {
  padding-top: 50px;
  padding-bottom: 80px;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .button-s, .button-s-red {
    padding-top: 16px;
    padding-bottom: 40px;
  }
}

.button-s a, .button-s-red a {
  display: inline-block;
  width: 420px;
  padding: 18px 0;
  border: 2px solid #fff;
  border-radius: 100px;
  background: url(/img/common/icn_btn_wh.svg) 40px center no-repeat, linear-gradient(-90deg, #e62162 0%, #ed4988 100%);
  color: #fff;
  font-size: 24px;
  font-weight: 900;
  text-decoration: none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 760px) {
  .button-s a, .button-s-red a {
    width: 90vw;
    padding: 13px 0;
    font-size: 20px;
    background-position: 30px center, 0 0;
    background-size: 12px 16px, auto;
  }
}

.button-s a span, .button-s-red a span {
  color: #fff;
  transition: .5s;
}

@media screen and (min-width: 761px) {
  .button-s a:hover, .button-s-red a:hover {
    background: url(/img/common/icn_btn_red.svg) 40px center no-repeat, linear-gradient(90deg, #ffef9f 0%, #fce67d 100%);
    color: #E8316E;
  }
}

@media screen and (min-width: 761px) {
  .button-s a:hover span, .button-s-red a:hover span {
    color: #E8316E;
  }
}

.button-s-green {
  padding-top: 50px;
  padding-bottom: 80px;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .button-s-green {
    padding-top: 16px;
    padding-bottom: 40px;
  }
}

.button-s-green a {
  display: inline-block;
  width: 420px;
  padding: 18px 0;
  border: 2px solid #fff;
  border-radius: 100px;
  background: url(/img/common/icn_btn_wh.svg) 40px center no-repeat, linear-gradient(-90deg, #90d127 0%, #79bf07 100%);
  color: #fff;
  font-size: 24px;
  font-weight: 900;
  text-decoration: none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 760px) {
  .button-s-green a {
    width: 90vw;
    padding: 13px 0;
    font-size: 20px;
    background-position: 30px center, 0 0;
    background-size: 12px 16px, auto;
  }
}

.button-s-green a span {
  color: #fff;
  transition: .5s;
}

@media screen and (min-width: 761px) {
  .button-s-green a:hover {
    background: url(/img/common/icn_btn_orn.svg) 40px center no-repeat, linear-gradient(90deg, #ffef9f 0%, #fce67d 100%);
    color: #F6680F;
  }
}

@media screen and (min-width: 761px) {
  .button-s-green a:hover span {
    color: #F6680F;
  }
}

.button-s-orange {
  padding-top: 50px;
  padding-bottom: 80px;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .button-s-orange {
    padding-top: 16px;
    padding-bottom: 40px;
  }
}

.button-s-orange a {
  display: inline-block;
  width: 420px;
  padding: 18px 0;
  border: 2px solid #fff;
  border-radius: 100px;
  background: url(/img/common/icn_btn_wh.svg) 40px center no-repeat, linear-gradient(-90deg, #f8aa2d 0%, #f69d0f 100%);
  color: #fff;
  font-size: 24px;
  font-weight: 900;
  text-decoration: none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 760px) {
  .button-s-orange a {
    width: 90vw;
    padding: 13px 0;
    font-size: 20px;
    background-position: 30px center, 0 0;
    background-size: 12px 16px, auto;
  }
}

.button-s-orange a span {
  color: #fff;
  transition: .5s;
}

@media screen and (min-width: 761px) {
  .button-s-orange a:hover {
    background: url(/img/common/icn_btn_orn.svg) 40px center no-repeat, linear-gradient(90deg, #ffef9f 0%, #fce67d 100%);
    color: #F6680F;
  }
}

@media screen and (min-width: 761px) {
  .button-s-orange a:hover span {
    color: #F6680F;
  }
}

.table-scroll {
  overflow: auto;
  white-space: nowrap;
}

.table-scroll::-webkit-scrollbar {
  height: 5px;
}

.table-scroll::-webkit-scrollbar-track {
  background: #F1F1F1;
}

.table-scroll::-webkit-scrollbar-thumb {
  background: #BCBCBC;
}

@media screen and (max-width: 760px) {
  .table-scroll table th {
    display: table-cell !important;
    width: auto !important;
  }
}

@media screen and (max-width: 760px) {
  .table-scroll table td {
    display: table-cell !important;
    width: auto !important;
  }
}

@media screen and (max-width: 760px) {
  .not-scroll table th {
    display: block;
    width: 100%;
    padding: 10px 15px;
  }
}

@media screen and (max-width: 760px) {
  .not-scroll table td {
    display: block;
    width: 100%;
    padding: 15px 0;
  }
}

.table-01 {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 60px;
  border-top: 1px solid #8D8D8D;
}

.table-01 tr {
  border-bottom: 1px solid #8D8D8D;
}

.table-01 th {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 20%;
  padding: 20px;
  background: #F5F5F5;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  vertical-align: top;
}

@media screen and (max-width: 760px) {
  .table-01 th {
    width: auto;
    line-height: 1.2;
  }
}

.table-01 td {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px;
  font-size: 15px;
  line-height: 1.5;
}

@media screen and (max-width: 760px) {
  .table-01 td {
    font-size: 15px;
  }
}

.block01 {
  background: #FFE76A;
}

.block01_inner {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  min-width: 1200px;
}

@media screen and (max-width: 760px) {
  .block01_inner {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

@media screen and (max-width: 760px) {
  .block01_inner {
    min-width: auto;
  }
}

.block01_box01 {
  position: relative;
}

.block01_title {
  margin-bottom: 50px;
  padding-top: 80px;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .block01_title {
    padding-top: 60px;
    margin-bottom: 25px;
  }
}

.block01_text01 {
  margin-bottom: 10px;
  font-size: 36px;
  font-weight: 700;
}

@media screen and (max-width: 760px) {
  .block01_text01 {
    font-size: 16px;
  }
}

.block01_text01 span {
  position: relative;
  display: inline-block;
  padding: 0 30px;
}

@media screen and (max-width: 760px) {
  .block01_text01 span {
    padding: 0 16px;
  }
}

.block01_text01 span::before {
  position: absolute;
  top: 22px;
  left: 0;
  content: "";
  display: block;
  width: 2px;
  height: 30px;
  background: #000;
  transform: rotate(-30deg);
}

@media screen and (max-width: 760px) {
  .block01_text01 span::before {
    top: 10px;
    width: 1px;
    height: 18px;
  }
}

.block01_text01 span::after {
  position: absolute;
  top: 22px;
  right: 0;
  content: "";
  display: block;
  width: 2px;
  height: 30px;
  background: #000;
  transform: rotate(30deg);
}

@media screen and (max-width: 760px) {
  .block01_text01 span::after {
    top: 10px;
    width: 1px;
    height: 18px;
  }
}

.block01_text02 {
  margin-bottom: 20px;
  font-size: 56px;
  font-weight: 900;
  line-height: 1;
}

@media screen and (max-width: 760px) {
  .block01_text02 {
    font-size: 18px;
  }
}

.block01_text02_ja {
  color: #24B8BC;
}

.block01_text02_en {
  color: #E8316E;
}

.block01_text03 {
  font-size: 78px;
  font-weight: 900;
  line-height: 1;
}

@media screen and (max-width: 760px) {
  .block01_text03 {
    font-size: 25px;
  }
}

.block01_text04 {
  margin-bottom: 40px;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: .1em;
  line-height: 1.6;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .block01_text04 {
    margin-bottom: 20px;
    font-size: 14px;
    text-align: left;
  }
}

.block01_image01 {
  position: absolute;
  left: -30px;
  bottom: 23px;
  width: 313px;
}

@media screen and (max-width: 760px) {
  .block01_image01 {
    position: static;
    width: 130px;
    margin-left: 20px;
  }
}

.block01_image02 {
  position: absolute;
  right: -100px;
  bottom: -16px;
  width: 400px;
}

@media screen and (max-width: 760px) {
  .block01_image02 {
    top: -64px;
    right: 0;
    bottom: auto;
    width: 180px;
  }
}

.block01_image03 {
  width: 545px;
  margin: 0 auto;
  padding-bottom: 34px;
}

@media screen and (max-width: 760px) {
  .block01_image03 {
    width: 90vw;
    padding-bottom: 20px;
  }
}

.block02 {
  margin-top: 60px;
  margin-bottom: 60px;
}

@media screen and (max-width: 760px) {
  .block02 {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.block02_title01 {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.4;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .block02_title01 {
    font-size: 14px;
  }
}

.block02_title01 span {
  color: #1C909F;
}

.block02_image01 {
  position: relative;
  margin-bottom: 70px;
  padding-top: 46px;
  padding-bottom: 60px;
  background: #effbfc;
  background: linear-gradient(0deg, #effbfc 0%, white 100%);
  text-align: center;
}

@media screen and (max-width: 760px) {
  .block02_image01 {
    margin-bottom: 35px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.block02_image01::after {
  position: absolute;
  bottom: -65px;
  left: 50%;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 65px 80px 0 80px;
  border-color: #effbfc transparent transparent transparent;
  transform: translateX(-50%);
}

@media screen and (max-width: 760px) {
  .block02_image01::after {
    bottom: -27px;
    border-width: 27px 34px 0 34px;
  }
}

.block02_image01 img {
  width: 86%;
}

@media screen and (max-width: 760px) {
  .block02_image01 img {
    width: 100%;
  }
}

.block02_title02 {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 46px;
  font-size: 46px;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .block02_title02 {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

@media screen and (max-width: 760px) {
  .block02_title02 {
    margin-bottom: 30px;
    font-size: 20px;
  }
}

.block02_title02 span {
  display: inline-block;
  border-bottom: 4px solid #E8316E;
  color: #E8316E;
}

@media screen and (max-width: 760px) {
  .block02_title02 span {
    border-width: 2px;
  }
}

.block02_box01 {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0px;
  padding-right: 0px;
  padding: 35px 30px;
  border: 4px solid #FFE76A;
  border-radius: 10px;
  background: #FFFAE3;
  line-height: 1.5;
}

@media screen and (max-width: 760px) {
  .block02_box01 {
    display: block;
    width: auto;
  }
}

@media screen and (max-width: 760px) {
  .block02_box01 {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 760px) {
  .block02_box01 {
    margin-left: 5vw;
    margin-right: 5vw;
    padding: 22px 10px;
  }
}

.block02_text01 {
  display: inline-block;
  margin-right: 30px;
  padding: 0 40px;
  border-radius: 6px;
  background: #F6AB0F;
  color: #fff;
  font-size: 26px;
  font-weight: 700;
}

@media screen and (max-width: 760px) {
  .block02_text01 {
    display: block;
    width: 40vw;
    margin: 0 auto 5px;
    padding: 6px 0;
    font-size: 17px;
    text-align: center;
  }
}

.block02_text02 {
  font-size: 30px;
  font-weight: 700;
}

@media screen and (max-width: 760px) {
  .block02_text02 {
    font-size: 20px;
    line-height: 1.3;
  }
}

.block02_box02 {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
}

@media screen and (max-width: 760px) {
  .block02_box02 {
    display: block;
    width: auto;
  }
}

@media screen and (max-width: 760px) {
  .block02_box02 {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

.block02_box02_detail {
  padding-top: 46px;
}

@media screen and (max-width: 760px) {
  .block02_box02_detail {
    padding-top: 30px;
  }
}

.block02_text03 {
  font-size: 30px;
  font-weight: 700;
}

@media screen and (max-width: 760px) {
  .block02_text03 {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 1.3;
  }
}

.block02_text03 strong {
  color: #24B8BC;
}

.block02_image02 {
  width: 430px;
}

@media screen and (max-width: 760px) {
  .block02_image02 {
    width: 80vw;
    margin-bottom: 10px;
  }
}

.block02_text04 {
  font-size: 20px;
}

@media screen and (max-width: 760px) {
  .block02_text04 {
    font-size: 13px;
    line-height: 1.5;
  }
}

.block02_text04 strong {
  color: #E8316E;
  font-size: 22px;
}

@media screen and (max-width: 760px) {
  .block02_text04 strong {
    font-size: 14px;
  }
}

.block02_image03 {
  text-align: center;
}

.block02_image03 img {
  width: 900px;
}

@media screen and (max-width: 760px) {
  .block02_image03 img {
    width: 90vw;
  }
}

.block03 {
  margin-top: 60px;
  background: url(/img/top/bg_block03_01.png) center 50px no-repeat;
  background-size: cover;
}

@media screen and (max-width: 760px) {
  .block03 {
    margin-top: 30px;
    background-position: center 25px;
  }
}

.block03_phase01 {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
}

@media screen and (max-width: 760px) {
  .block03_phase01 {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

.block03_icon {
  text-align: center;
}

.block03_icon figure {
  display: inline-block;
  width: 100px;
  height: 100px;
}

@media screen and (max-width: 760px) {
  .block03_icon figure {
    width: 50px;
    height: 50px;
  }
}

.block03_icon figure figcaption {
  color: #24B8BC;
  font-weight: 600;
}

@media screen and (max-width: 760px) {
  .block03_icon figure figcaption {
    font-size: 14px;
  }
}

.block03_title {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .block03_title {
    font-size: 15px;
  }
}

.block03_text01 {
  font-size: 46px;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .block03_text01 {
    margin-bottom: 10px;
    font-size: 25px;
  }
}

.block03_text01 strong {
  display: inline;
  border-bottom: 4px solid #E8316E;
  color: #E8316E;
}

@media screen and (max-width: 760px) {
  .block03_text01 strong {
    border-width: 2px;
  }
}

.block03_text02 {
  margin-bottom: 30px;
  font-size: 40px;
  color: #24B8BC;
  font-weight: 700;
  letter-spacing: .1em;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .block03_text02 {
    margin-bottom: 20px;
    font-size: 20px;
  }
}

.block03_box01 {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 40px;
  padding: 20px 30px 30px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 5px 20px rgba(105, 192, 207, 0.4);
}

@media screen and (max-width: 760px) {
  .block03_box01 {
    display: block;
    width: auto;
  }
}

@media screen and (max-width: 760px) {
  .block03_box01 {
    padding: 20px 20px 30px;
    border: 5px;
  }
}

.block03_graph img {
  width: 470px;
}

@media screen and (max-width: 760px) {
  .block03_graph img {
    width: auto;
  }
}

.block03_caption {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .block03_caption {
    font-size: 15px;
  }
}

.block03_detail {
  width: 500px;
  padding-top: 30px;
}

@media screen and (max-width: 760px) {
  .block03_detail {
    width: auto;
  }
}

.block03_detail_text {
  margin-bottom: 60px;
  font-size: 24px;
}

@media screen and (max-width: 760px) {
  .block03_detail_text {
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 1.8;
  }
}

.block03_supple {
  font-size: 16px;
  line-height: 1.8;
}

@media screen and (max-width: 760px) {
  .block03_supple {
    font-size: 12px;
  }
}

.block03_supple span {
  vertical-align: middle;
}

.block03_supple span img {
  width: 62px;
  margin-right: 10px;
  vertical-align: middle;
}

@media screen and (max-width: 760px) {
  .block03_supple span img {
    width: 31px;
  }
}

.block03_note {
  padding-top: 10px;
  font-size: 12px;
}

@media screen and (max-width: 760px) {
  .block03_note {
    line-height: 1.5;
  }
}

.block03_text03 {
  font-size: 30px;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .block03_text03 {
    font-size: 15px;
  }
}

.block03_text04 {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .block03_text04 {
    font-size: 18px;
  }
}

.block03_text04 span {
  color: #24B8BC;
}

.block03_phase02 {
  padding-top: 80px;
  padding-bottom: 100px;
  background: url(/img/top/bg_block03_02.png) center top no-repeat;
  background-size: cover;
}

@media screen and (max-width: 760px) {
  .block03_phase02 {
    padding-top: 40px;
    padding-bottom: 50px;
  }
}

.block03_text05 {
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .block03_text05 {
    font-size: 16px;
  }
}

.block03_text05 strong {
  color: #FFE76A;
}

.block03_text06 {
  margin-bottom: 50px;
  color: #fff;
  font-size: 30px;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .block03_text06 {
    margin-bottom: 30px;
    font-size: 15px;
  }
}

.block03_box02 {
  position: relative;
  width: 900px;
  margin: 0 auto;
  padding: 25px 0 25px 240px;
  border-radius: 10px;
  background: #79BF07;
  color: #fff;
}

@media screen and (max-width: 760px) {
  .block03_box02 {
    width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
    padding: 15px 0 15px 75px;
  }
}

.block03_box02_icn {
  position: absolute;
  top: -20px;
  left: 40px;
  width: 150px;
}

@media screen and (max-width: 760px) {
  .block03_box02_icn {
    top: -10px;
    left: -10px;
    width: 75px;
  }
}

.block03_box02_text01 {
  font-size: 26px;
  line-height: 1.5;
}

@media screen and (max-width: 760px) {
  .block03_box02_text01 {
    font-size: 13px;
  }
}

.block03_box02_text02 {
  font-size: 30px;
  font-weight: 700;
  line-height: 1.5;
}

@media screen and (max-width: 760px) {
  .block03_box02_text02 {
    font-size: 15px;
  }
}

.block03_box02_text03 {
  font-size: 20px;
  line-height: 1.5;
}

@media screen and (max-width: 760px) {
  .block03_box02_text03 {
    font-size: 12px;
  }
}

.block04 {
  margin-top: 60px;
  margin-bottom: 80px;
}

@media screen and (max-width: 760px) {
  .block04 {
    margin-top: 30px;
    margin-bottom: 40px;
  }
}

.block04_phase01 {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 760px) {
  .block04_phase01 {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

.block04_icon {
  text-align: center;
}

.block04_icon figure {
  display: inline-block;
  width: 100px;
  height: 100px;
}

@media screen and (max-width: 760px) {
  .block04_icon figure {
    width: 50px;
    height: 50px;
  }
}

.block04_icon figure figcaption {
  color: #24B8BC;
  font-weight: 600;
}

@media screen and (max-width: 760px) {
  .block04_icon figure figcaption {
    font-size: 14px;
  }
}

.block04_title {
  margin-bottom: 40px;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .block04_title {
    margin-bottom: 20px;
    font-size: 15px;
  }
}

.block04_box01 {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 80px;
}

@media screen and (max-width: 760px) {
  .block04_box01 {
    display: block;
    width: auto;
  }
}

@media screen and (max-width: 760px) {
  .block04_box01 {
    margin-bottom: 40px;
  }
}

.block04_graph {
  width: 30%;
}

@media screen and (max-width: 760px) {
  .block04_graph {
    width: auto;
  }
}

.block04_caption {
  margin-bottom: 20px;
  background: #24B8BC;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .block04_caption {
    width: 70vw;
    margin: 0 auto 15px;
    padding: 2px 0;
    font-size: 15px;
    font-weight: 500;
  }
}

.block04_image01, .block04_image02, .block04_image03 {
  margin-bottom: 30px;
  text-align: center;
}

.block04_image01 img, .block04_image02 img, .block04_image03 img {
  width: 66vw;
}

.block04_box02 {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

@media screen and (max-width: 760px) {
  .block04_box02 {
    flex-wrap: wrap;
  }
}

.block04_image04 {
  width: 50%;
}

.block04_image04:last-child {
  flex-grow: 2;
}

.block04_phase03 {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  z-index: 10;
}

@media screen and (max-width: 760px) {
  .block04_phase03 {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.block04_phase03 ul {
  margin-top: -50px;
  padding: 40px 160px;
  background: rgba(255, 231, 106, 0.7);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
}

@media screen and (max-width: 760px) {
  .block04_phase03 ul {
    padding: 20px 5vw 50px;
  }
}

.block04_phase03 ul li {
  margin-bottom: 5px;
  padding-left: 50px;
  background: url(/img/top/icn_check.svg) left center no-repeat;
  background-size: 33px 33px;
  font-size: 24px;
  font-weight: 700;
}

@media screen and (max-width: 760px) {
  .block04_phase03 ul li {
    margin-bottom: 10px;
    padding-left: 30px;
    background-position: left 2px;
    background-size: 20px 20px;
    font-size: 14px;
    line-height: 1.6;
  }
}

.block04_text01 {
  position: absolute;
  bottom: 46px;
  right: 100px;
  font-size: 20px;
  font-weight: 500;
}

@media screen and (max-width: 760px) {
  .block04_text01 {
    bottom: 20px;
    left: 5vw;
    right: auto;
    font-size: 12px;
  }
}

.block05 {
  padding: 70px 0 80px;
  background: #F5F5F5;
}

@media screen and (max-width: 760px) {
  .block05 {
    padding: 30px 0;
  }
}

.block05_inner {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 760px) {
  .block05_inner {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.block05_text01 {
  margin-bottom: 30px;
  color: #24B8BC;
  font-size: 36px;
  font-weight: 500;
  line-height: 1.4;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .block05_text01 {
    margin-bottom: 20px;
    font-size: 17px;
  }
}

.block05 table {
  width: 960px;
  margin: 0 auto;
  border-top: 1px solid #BCBCC4;
}

@media screen and (max-width: 760px) {
  .block05 table {
    width: 100vw;
  }
}

.block05 table tr th {
  padding: 15px;
  border-bottom: 1px solid #BCBCC4;
  font-size: 18px;
  font-weight: 500;
}

@media screen and (max-width: 760px) {
  .block05 table tr th {
    display: block;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 0;
    border-bottom: 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.6;
  }
}

.block05 table tr td {
  padding: 15px;
  border-bottom: 1px solid #BCBCC4;
  font-size: 18px;
}

@media screen and (max-width: 760px) {
  .block05 table tr td {
    display: block;
    width: 100%;
    padding-top: 0;
    padding-bottom: 10px;
    font-size: 18px;
    line-height: 1.6;
    text-align: center;
  }
}

.block06 {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 60px;
  margin-bottom: 80px;
}

@media screen and (max-width: 760px) {
  .block06 {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

@media screen and (max-width: 760px) {
  .block06 {
    margin-top: 30px;
    margin-bottom: 40px;
  }
}

.block06_text01 {
  margin-bottom: 50px;
  font-size: 36px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .block06_text01 {
    margin-bottom: 20px;
    font-size: 16px;
  }
}

.block06_text01 strong {
  display: inline;
  border-bottom: 4px solid #E8316E;
  color: #E8316E;
  font-weight: 500;
}

@media screen and (max-width: 760px) {
  .block06_text01 strong {
    border-width: 2px;
  }
}

.block06_box01 {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 920px;
  margin: 0 auto 50px;
}

@media screen and (max-width: 760px) {
  .block06_box01 {
    display: block;
    width: auto;
  }
}

@media screen and (max-width: 760px) {
  .block06_box01 {
    width: auto;
    margin-bottom: 30px;
  }
}

.block06_image01, .block06_image02 {
  width: 47%;
}

@media screen and (max-width: 760px) {
  .block06_image01, .block06_image02 {
    width: 65vw;
    margin: 0 auto 20px;
  }
}

.block06_text02 {
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .block06_text02 {
    font-size: 12px;
  }
}

.block06_text03 {
  font-size: 20px;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .block06_text03 {
    font-size: 14px;
  }
}

.block07_phase01 {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

@media screen and (max-width: 760px) {
  .block07_phase01 {
    flex-wrap: wrap;
  }
}

.block07_image01 {
  width: 50%;
}

.block07_image01:last-child {
  flex-grow: 2;
}

.block07_phase02 {
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
  background: #DFF4BE;
}

.block07_phase02::before {
  position: absolute;
  top: -50px;
  left: 0;
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 0 0 100vw;
  border-color: transparent transparent transparent #DFF4BE;
}

@media screen and (max-width: 760px) {
  .block07_phase02::before {
    top: -25px;
    border-width: 25px 0 0 100vw;
  }
}

.block07_phase02::after {
  position: absolute;
  bottom: -50px;
  left: 0;
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 100vw 50px 0;
  border-color: transparent #DFF4BE transparent transparent;
}

@media screen and (max-width: 760px) {
  .block07_phase02::after {
    bottom: -25px;
    border-width: 0 100vw 25px 0;
  }
}

.block07_phase02_inner {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

@media screen and (max-width: 760px) {
  .block07_phase02_inner {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

@media screen and (max-width: 760px) {
  .block07_phase02_inner {
    display: block;
    width: auto;
  }
}

.block07_phase02_title {
  width: 20%;
  font-size: 36px;
  font-weight: 500;
}

@media screen and (max-width: 760px) {
  .block07_phase02_title {
    width: 100%;
    font-size: 20px;
  }
}

.block07_phase02_text {
  width: 80%;
  font-size: 20px;
}

@media screen and (max-width: 760px) {
  .block07_phase02_text {
    width: 100%;
    font-size: 16px;
    line-height: 1.8;
  }
}

.block07_phase03 {
  padding-top: 80px;
  padding-bottom: 80px;
  background: #F4FBEB;
}

@media screen and (max-width: 760px) {
  .block07_phase03 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.block07_phase03_inner {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 760px) {
  .block07_phase03_inner {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

.block07_title {
  margin-bottom: 10px;
  font-size: 36px;
  font-weight: 500;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .block07_title {
    font-size: 20px;
  }
}

.block07_box01 {
  margin-bottom: 80px;
}

@media screen and (max-width: 760px) {
  .block07_box01 {
    margin-bottom: 40px;
  }
}

.block07_box01 ol {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media screen and (max-width: 760px) {
  .block07_box01 ol {
    display: block;
    width: auto;
  }
}

.block07_box01 ol li {
  width: 30%;
  list-style: none;
}

@media screen and (max-width: 760px) {
  .block07_box01 ol li {
    width: 100%;
    margin-bottom: 20px;
  }
}

.block07_icon {
  margin-bottom: 20px;
  text-align: center;
}

.block07_icon img {
  width: 260px;
}

@media screen and (max-width: 760px) {
  .block07_icon img {
    width: 170px;
  }
}

.block07_text01 {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .block07_text01 {
    font-size: 20px;
  }
}

.block07_text02 {
  color: #79BF07;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.8;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .block07_text02 {
    font-size: 18px;
    line-height: 1.6;
  }
}

.block08 {
  margin-top: 60px;
  margin-bottom: 80px;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 760px) {
  .block08 {
    margin-top: 30px;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 760px) {
  .block08 {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

.block08_icon {
  text-align: center;
}

.block08_icon figure {
  display: inline-block;
  width: 100px;
  height: 100px;
}

@media screen and (max-width: 760px) {
  .block08_icon figure {
    width: 50px;
    height: 50px;
  }
}

.block08_icon figure figcaption {
  color: #24B8BC;
  font-weight: 600;
}

@media screen and (max-width: 760px) {
  .block08_icon figure figcaption {
    font-size: 14px;
  }
}

.block08_title {
  margin-bottom: 50px;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .block08_title {
    margin-bottom: 25px;
    font-size: 15px;
  }
}

.block08_voice {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 40px;
}

.block08_voice_image {
  width: 140px;
}

@media screen and (max-width: 760px) {
  .block08_voice_image {
    width: 70px;
  }
}

.block08_voice_text {
  position: relative;
  width: calc(100% - 200px);
  padding: 30px 40px;
  border-radius: 10px;
  background: #F5F5F5;
}

@media screen and (max-width: 760px) {
  .block08_voice_text {
    width: calc(100% - 90px);
    padding: 15px;
    line-height: 1.8;
  }
}

.block08_voice_text::before {
  position: absolute;
  top: 50%;
  left: -30px;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 32px 46px;
  border-color: transparent transparent #F5F5F5 transparent;
}

@media screen and (max-width: 760px) {
  .block08_voice_text::before {
    top: 30px;
    left: -18px;
    border-width: 0 0 16px 23px;
  }
}

.block09 {
  padding: 80px 0;
  background: #47C2D1;
}

@media screen and (max-width: 760px) {
  .block09 {
    padding: 30px 0;
  }
}

.block09_inner {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0px;
  padding-right: 0px;
}

@media screen and (max-width: 760px) {
  .block09_inner {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.block09_title {
  margin-bottom: 40px;
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .block09_title {
    margin-bottom: 20px;
    font-size: 20px;
  }
}

.block09_title span {
  display: inline-block;
  border-bottom: 4px solid #fff;
}

@media screen and (max-width: 760px) {
  .block09_title span {
    border-width: 2px;
  }
}

.block09_box {
  padding: 80px 90px;
  background: #fff;
}

@media screen and (max-width: 760px) {
  .block09_box {
    margin-left: 5vw;
    margin-right: 5vw;
    padding: 20px;
  }
}

@media screen and (max-width: 760px) {
  .block09_box dl {
    line-height: 1.6;
  }
}

.block09_box dl dt {
  position: relative;
  padding-left: 60px;
  margin-bottom: 20px;
  color: #24B8BC;
  font-weight: 700;
  line-height: 1.8;
}

@media screen and (max-width: 760px) {
  .block09_box dl dt {
    margin-bottom: 10px;
    padding-left: 30px;
  }
}

.block09_box dl dt::before {
  position: absolute;
  left: 0;
  top: 2px;
  content: '';
  display: block;
  width: 29px;
  height: 30px;
  background: url(/img/top/icn_q.svg) 0 0 no-repeat;
  background-size: 29px 30px;
}

@media screen and (max-width: 760px) {
  .block09_box dl dt::before {
    top: 5px;
    width: 18px;
    height: 18px;
    background-size: 18px 18px;
  }
}

.block09_box dl dd {
  position: relative;
  margin-bottom: 40px;
  padding-left: 60px;
  line-height: 1.8;
}

@media screen and (max-width: 760px) {
  .block09_box dl dd {
    margin-bottom: 20px;
    padding-left: 30px;
  }
}

.block09_box dl dd::before {
  position: absolute;
  left: 0;
  top: 2px;
  content: '';
  display: block;
  width: 31px;
  height: 29px;
  background: url(/img/top/icn_a.svg) 0 0 no-repeat;
  background-size: 31px 29px;
}

@media screen and (max-width: 760px) {
  .block09_box dl dd::before {
    top: 5px;
    width: 18px;
    height: 18px;
    background-size: 18px 18px;
  }
}

.block10 {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 60px;
  margin-bottom: 80px;
}

@media screen and (max-width: 760px) {
  .block10 {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

@media screen and (max-width: 760px) {
  .block10 {
    margin-top: 30px;
    margin-bottom: 40px;
  }
}

.block10_title {
  width: 530px;
  height: 87px;
  margin: 0 auto 50px;
  padding-top: 6px;
  background: url(/img/top/bg_block09_ttl.png) center top no-repeat;
  background-size: 530px auto;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .block10_title {
    width: 270px;
    height: 43px;
    padding-top: 0;
    background-size: 270px auto;
    font-size: 18px;
  }
}

.block10_box01 {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  min-width: 1100px;
  position: relative;
  margin-bottom: 65px;
  padding: 30px;
  border-radius: 10px;
  background: #FDF0F5;
}

@media screen and (max-width: 760px) {
  .block10_box01 {
    display: block;
    width: auto;
  }
}

@media screen and (max-width: 760px) {
  .block10_box01 {
    min-width: auto;
    margin-bottom: 40px;
    padding: 15px 5vw;
    border-radius: 6px;
  }
}

.block10_box01::after {
  position: absolute;
  bottom: -50px;
  left: 50%;
  content: '';
  display: block;
  width: 40px;
  height: 40px;
  background: url(/img/top/icn_plus.svg) 0 0 no-repeat;
  background-size: 40px 40px;
  transform: translateX(-50%);
}

@media screen and (max-width: 760px) {
  .block10_box01::after {
    bottom: -30px;
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
  }
}

.block10_box01_icon {
  width: 150px;
}

@media screen and (max-width: 760px) {
  .block10_box01_icon {
    width: 75px;
    margin: 0 auto;
  }
}

.block10_box01_detail {
  position: relative;
  width: calc(100% - 180px);
  padding-top: 20px;
}

@media screen and (max-width: 760px) {
  .block10_box01_detail {
    width: 100%;
    padding-top: 10px;
  }
}

.block10_text01 {
  color: #fff;
  font-size: 26px;
  font-weight: 700;
  line-height: 1.5;
}

@media screen and (max-width: 760px) {
  .block10_text01 {
    margin-bottom: 5px;
    font-size: 14px;
  }
}

.block10_text01 span {
  display: inline-block;
  padding: 2px 30px 5px;
  border-radius: 6px;
  background: #E8316E;
}

@media screen and (max-width: 760px) {
  .block10_text01 span {
    padding: 4px 15px;
  }
}

.block10_text02 {
  margin-bottom: 10px;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.8;
}

@media screen and (max-width: 760px) {
  .block10_text02 {
    font-size: 18px;
  }
}

.block10_text02 span {
  display: inline;
  border-bottom: 4px solid #E8316E;
}

@media screen and (max-width: 760px) {
  .block10_text02 span {
    border-width: 2px;
  }
}

.block10_text03 {
  font-size: 26px;
  font-weight: 700;
}

@media screen and (max-width: 760px) {
  .block10_text03 {
    font-size: 15px;
    line-height: 1.6;
  }
}

.block10_text04 {
  font-size: 20px;
  font-weight: 500;
}

@media screen and (max-width: 760px) {
  .block10_text04 {
    font-size: 14px;
    line-height: 1.6;
  }
}

.block10_text04 span {
  color: #E8316E;
  font-weight: 700;
}

.block10_image01 {
  position: absolute;
  right: 0;
  bottom: 20px;
  width: 300px;
}

@media screen and (max-width: 760px) {
  .block10_image01 {
    position: static;
    width: 70vw;
    margin: 10px auto;
  }
}

.block10_image02 {
  position: absolute;
  top: 10px;
  right: 0;
  width: 245px;
}

@media screen and (max-width: 760px) {
  .block10_image02 {
    position: static;
    width: 40vw;
    margin: 10px auto;
  }
}

.block10_box02 {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  position: relative;
  margin-bottom: 65px;
  padding: 30px;
  border-radius: 10px;
  background: #F4FBEB;
}

@media screen and (max-width: 760px) {
  .block10_box02 {
    display: block;
    width: auto;
  }
}

@media screen and (max-width: 760px) {
  .block10_box02 {
    margin-bottom: 40px;
    padding: 15px 5vw;
    border-radius: 6px;
  }
}

.block10_box02_icon {
  width: 150px;
}

@media screen and (max-width: 760px) {
  .block10_box02_icon {
    width: 75px;
    margin: 0 auto;
  }
}

.block10_box02_detail {
  position: relative;
  width: calc(100% - 180px);
  padding-top: 20px;
  width: 100%;
  padding-top: 10px;
}

.block10_text05 {
  color: #fff;
  font-size: 26px;
  font-weight: 700;
  line-height: 1.5;
}

@media screen and (max-width: 760px) {
  .block10_text05 {
    font-size: 14px;
  }
}

.block10_text05 span {
  display: inline-block;
  padding: 2px 30px 5px;
  border-radius: 6px;
  background: #79BF07;
}

@media screen and (max-width: 760px) {
  .block10_text05 span {
    padding: 4px 15px;
  }
}

.block10_text06 {
  margin-bottom: 10px;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.8;
}

@media screen and (max-width: 760px) {
  .block10_text06 {
    font-size: 18px;
  }
}

.block10_text06 span {
  display: inline;
  border-bottom: 4px solid #79BF07;
}

@media screen and (max-width: 760px) {
  .block10_text06 span {
    border-width: 2px;
  }
}

.block10_text07 {
  font-size: 20px;
  font-weight: 500;
}

@media screen and (max-width: 760px) {
  .block10_text07 {
    font-size: 14px;
    line-height: 1.6;
  }
}

.block11 {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 60px;
  margin-bottom: 80px;
}

@media screen and (max-width: 760px) {
  .block11 {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

@media screen and (max-width: 760px) {
  .block11 {
    margin-top: 30px;
    margin-bottom: 40px;
  }
}

.block11_part {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 60px;
}

@media screen and (max-width: 760px) {
  .block11_part {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

@media screen and (max-width: 760px) {
  .block11_part {
    margin-top: 30px;
  }
}

.block11_icon {
  text-align: center;
}

.block11_icon figure {
  display: inline-block;
  width: 100px;
  height: 100px;
}

@media screen and (max-width: 760px) {
  .block11_icon figure {
    width: 50px;
    height: 50px;
  }
}

.block11_icon figure figcaption {
  color: #24B8BC;
  font-weight: 600;
}

@media screen and (max-width: 760px) {
  .block11_icon figure figcaption {
    font-size: 14px;
  }
}

.block11_title {
  margin-bottom: 70px;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .block11_title {
    margin-bottom: 30px;
    font-size: 15px;
  }
}

.block11_list li {
  margin-bottom: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid #BCBCC4;
}

@media screen and (max-width: 760px) {
  .block11_list li {
    margin-bottom: 25px;
    padding-bottom: 25px;
  }
}

.block11_list li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.block11_list_box {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media screen and (max-width: 760px) {
  .block11_list_box {
    display: block;
    width: auto;
  }
}

.block11_list_box_image {
  width: 33%;
}

@media screen and (max-width: 760px) {
  .block11_list_box_image {
    width: 80vw;
    margin: 0 auto 20px;
  }
}

.block11_list_box_detail {
  width: 60%;
}

@media screen and (max-width: 760px) {
  .block11_list_box_detail {
    width: 100%;
  }
}

.block11_list_box_detail_title {
  font-size: 20px;
  font-weight: 700;
}

@media screen and (max-width: 760px) {
  .block11_list_box_detail_title {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 1.5;
  }
}

.block11_list_box_detail table th {
  width: 90px;
  padding: 5px 0;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  vertical-align: top;
  white-space: nowrap;
}

@media screen and (max-width: 760px) {
  .block11_list_box_detail table th {
    line-height: 1.6;
  }
}

.block11_list_box_detail table td {
  width: calc(100% - 90px);
  padding: 5px 0;
  font-size: 16px;
}

@media screen and (max-width: 760px) {
  .block11_list_box_detail table td {
    line-height: 1.6;
  }
}

.block11_button {
  padding-top: 50px;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .block11_button {
    padding-top: 16px;
  }
}

.block11_button a {
  display: inline-block;
  width: 420px;
  padding: 18px 0;
  border: 2px solid #fff;
  border-radius: 100px;
  background: url(/img/common/icn_btn_wh.svg) 40px center no-repeat, linear-gradient(-90deg, #e62162 0%, #ed4988 100%);
  color: #fff;
  font-size: 24px;
  font-weight: 900;
  text-decoration: none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 760px) {
  .block11_button a {
    width: 90vw;
    padding: 13px 0;
    font-size: 20px;
    background-position: 30px center, 0 0;
    background-size: 12px 16px, auto;
  }
}

.block11_button a span {
  color: #fff;
  transition: .5s;
}

@media screen and (min-width: 761px) {
  .block11_button a:hover {
    background: url(/img/common/icn_btn_red.svg) 40px center no-repeat, linear-gradient(90deg, #ffef9f 0%, #fce67d 100%);
    color: #E8316E;
  }
}

@media screen and (min-width: 761px) {
  .block11_button a:hover span {
    color: #E8316E;
  }
}

.block12 {
  background: #F5F5F5;
}

.block12_inner {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 60px;
  padding-bottom: 60px;
  font-size: 16px;
}

@media screen and (max-width: 760px) {
  .block12_inner {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

.block12_text01 {
  margin-bottom: 20px;
  font-weight: 700;
}

@media screen and (max-width: 760px) {
  .block12_text01 {
    line-height: 1.6;
  }
}

.block12_text02 {
  margin-bottom: 20px;
}

.block12 a {
  color: #24B8BC;
  text-decoration: underline;
}

@media screen and (min-width: 761px) {
  .block12 a:hover {
    color: #8D8D8D;
  }
}

.covid19 {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 60px;
  margin-bottom: 60px;
}

@media screen and (max-width: 760px) {
  .covid19 {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

@media screen and (max-width: 760px) {
  .covid19 {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.covid19_inner {
  padding: 30px 68px 35px;
  border: 4px solid #24B8BC;
}

@media screen and (max-width: 760px) {
  .covid19_inner {
    padding: 15px 20px;
    border-width: 2px;
  }
}

.covid19_text {
  margin-bottom: 30px;
  color: #24B8BC;
  font-size: 30px;
  letter-spacing: 0;
  line-height: 1.5;
}

@media screen and (max-width: 760px) {
  .covid19_text {
    font-size: 15px;
    text-align: center;
  }
}

.covid19_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media screen and (max-width: 760px) {
  .covid19_list {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.covid19_list li {
  width: 17.5%;
}

@media screen and (max-width: 760px) {
  .covid19_list li {
    width: 22.4vw;
    margin-right: 4vw;
    margin-bottom: 10px;
  }
}

.covid19_list li:nth-child(3), .covid19_list li:nth-child(5) {
  margin-right: 0;
}

.covid19_list li figure img {
  margin-bottom: 15px;
}

@media screen and (max-width: 760px) {
  .covid19_list li figure img {
    margin-bottom: 5px;
  }
}

.covid19_list li figure figcaption {
  color: #707070;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .covid19_list li figure figcaption {
    font-size: 12px;
  }
}
