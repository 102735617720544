@charset "UTF-8";
/*!
 *
 * BurgerEditor StyleSheet Style Sample v2.21.0
 *
 */
 @font-face {
  font-family: FontAwesome;
  src: url(../fonts/fontawesome-webfont.eot?v=4.6.3);
  src: url(../fonts/fontawesome-webfont.eot?#iefix&v=4.6.3) format("embedded-opentype"), url(../fonts/fontawesome-webfont.woff2?v=4.6.3) format("woff2"), url(../fonts/fontawesome-webfont.woff?v=4.6.3) format("woff"), url(../fonts/fontawesome-webfont.ttf?v=4.6.3) format("truetype"), url(../fonts/fontawesome-webfont.svg?v=4.6.3#fontawesomeregular) format("svg");
  font-weight: 400;
  font-style: normal
}

[data-bgb] blockquote {
  position: relative;
  margin: 1em 0;
  padding: 1px 2.5em;
  border-radius: 5px;
  background: none;
  border: 2px solid #ccc;
  overflow: hidden;
  color: #888;
  font-size: .9em
}

[data-bgb] blockquote:first-child {
  margin-top: 0
}

[data-bgb] blockquote:before {
  content: "“";
  top: .3em;
  left: .25em
}

[data-bgb] blockquote:after, [data-bgb] blockquote:before {
  display: block;
  font-family: serif;
  position: absolute;
  z-index: 0;
  color: #ccc;
  font-size: 3em
}

[data-bgb] blockquote:after {
  content: "”";
  bottom: -.3em;
  right: .25em
}


[data-bgb] u {
  text-decoration: underline
}

[data-bgb] s, [data-bgb] u {
  font-weight: 400;
  font-style: normal
}

[data-bgb] s {
  text-decoration: line-through
}

[data-bgb] sub {
  vertical-align: sub
}

[data-bgb] sub, [data-bgb] sup {
  font-size: .8em;
  font-weight: 400;
  font-style: normal;
  text-decoration: none
}

[data-bgb] sup {
  vertical-align: super
}

[data-bgb] hr {
  width: 100%;
  height: 1px;
  color: #BCBCC4;
  background: currentColor;
  border-style: none;
  margin: 50px auto
}

[data-bgb] hr:first-child {
  margin-top: 0
}

.bgt-box__caption {
  text-align: left;
  font-weight: 400;
  padding: 3px;
  background: none;
  font-size: .8em
}

.bgt-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  vertical-align: middle;
  padding: 12px 10px;
  border-width: 1px;
  border-style: solid;
  background: #fff;
  text-decoration: none;
  min-width: 280px;
  border-radius: 2px
}

.bgt-btn:active, .bgt-btn:focus {
  -webkit-box-shadow: 0 0 10px 0 #0570c7, 0 0 10px 0 #fff;
  box-shadow: 0 0 10px 0 #0570c7, 0 0 10px 0 #fff
}

.bgt-btn--link, [data-bgt-button-kind=link] .bgt-btn {
  color: #333;
  border-color: #ccc;
  background: #fff
}

.bgt-btn--link:active, .bgt-btn--link:focus, .bgt-btn--link:hover, [data-bgt-button-kind=link] .bgt-btn:active, [data-bgt-button-kind=link] .bgt-btn:focus, [data-bgt-button-kind=link] .bgt-btn:hover {
  background: #e9e9e9
}

.bgt-btn--em, [data-bgt-button-kind=em] .bgt-btn {
  font-weight: 700;
  color: #fff;
  border-color: #333;
  background: #333
}

.bgt-btn--em:active, .bgt-btn--em:focus, .bgt-btn--em:hover, [data-bgt-button-kind=em] .bgt-btn:active, [data-bgt-button-kind=em] .bgt-btn:focus, [data-bgt-button-kind=em] .bgt-btn:hover {
  background: #555
}

.bgt-btn--external, [data-bgt-button-kind=external] .bgt-btn {
  color: #333;
  border-color: #ccc;
  background: #fff
}

.bgt-btn--external:active, .bgt-btn--external:focus, .bgt-btn--external:hover, [data-bgt-button-kind=external] .bgt-btn:active, [data-bgt-button-kind=external] .bgt-btn:focus, [data-bgt-button-kind=external] .bgt-btn:hover {
  background: #e9e9e9
}

.bgt-btn--external:after, [data-bgt-button-kind=external] .bgt-btn:after {
  font-family: FontAwesome, serif;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f08e";
  font-size: 1em;
  color: #999;
  vertical-align: middle
}

.bgt-btn--back, [data-bgt-button-kind=back] .bgt-btn {
  color: #333;
  border-color: #ddd;
  background: #ddd
}

.bgt-btn--back:active, .bgt-btn--back:focus, .bgt-btn--back:hover, [data-bgt-button-kind=back] .bgt-btn:active, [data-bgt-button-kind=back] .bgt-btn:focus, [data-bgt-button-kind=back] .bgt-btn:hover {
  background: #e9e9e9
}

.bgt-btn--back span, [data-bgt-button-kind=back] .bgt-btn span {
  display: inline-block;
  vertical-align: middle
}

.bgt-btn--back:before, [data-bgt-button-kind=back] .bgt-btn:before {
  font-family: FontAwesome, serif;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f053";
  font-size: 1em;
  margin-top: -2px;
  display: inline-block;
  vertical-align: middle
}

.bgt-hr {
  margin: 0 auto
}

[data-bgt-hr-kind=dashed] .bgt-hr {
  height: 0;
  background: none;
  border: none;
  border-bottom: 2px dashed
}

.bgt-hr--bold, [data-bgt-hr-kind=bold] .bgt-hr {
  height: 5px
}

.bgt-hr--narrow, [data-bgt-hr-kind=narrow] .bgt-hr {
  height: 1px
}

.bgt-hr--short, [data-bgt-hr-kind=short] .bgt-hr {
  width: 60%;
  height: 1px
}

.bgt-download-file__link {
  color: #333;
  text-align: left;
  font-size: 1.08em;
  font-weight: 700;
  display: inline-block;
  padding: .7em 1em;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fff;
  line-height: 1;
  text-decoration: none
}

.bgt-download-file__link:hover {
  background-color: #eee
}

.bgt-download-file__link:active, .bgt-download-file__link:focus {
  -webkit-box-shadow: 0 0 5px 1px #0570c7;
  box-shadow: 0 0 5px 1px #0570c7;
  outline: none
}

.bgt-link__size {
  color: grey;
  font-size: .8em;
  font-weight: 400
}

@media screen and (max-width:768px) {
  .bgt-google-maps-link {
    margin-top: 10px;
    padding: 1em;
    text-decoration: none;
    color: #333;
    border: 1px solid #999;
    border-radius: 3px;
    text-align: center
  }
  .bgt-google-maps-link span {
    display: inline-block;
    vertical-align: middle
  }
  .bgt-google-maps-link:after {
    font-family: FontAwesome, serif;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f08e";
    font-size: 1em;
    vertical-align: middle
  }
}

[data-bgt=gallery] .bgt-gallery {
  padding-top: 54%
}

[data-bgt=gallery] .bgt-gallery:hover [data-bgt=gallery] .bgt-gallery-ctrl {
  opacity: 1
}

[data-bgt=gallery] .bgt-gallery__img img {
  -o-object-fit: cover;
  font-family: "object-fit:cover";
  object-fit: cover
}

[data-bgt=gallery] .bgt-gallery__caption {
  color: #fff;
  background: rgba(0, 0, 0, .6);
  padding: .7em 1em;
  text-align: center
}

[data-bgt=gallery] .bgt-gallery-ctrl {
  top: 50%;
  opacity: 0;
  -webkit-transition: opacity .2s ease-in;
  transition: opacity .2s ease-in
}

[data-bgt=gallery] .bgt-gallery-ctrl__next, [data-bgt=gallery] .bgt-gallery-ctrl__prev {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: -20px 0 0;
  padding: 0;
  background: none;
  border: none;
  -webkit-transform-origin: center center;
  transform-origin: center center
}

[data-bgt=gallery] .bgt-gallery-ctrl__next:hover, [data-bgt=gallery] .bgt-gallery-ctrl__prev:hover {
  opacity: .5
}

[data-bgt=gallery] .bgt-gallery-ctrl__next:focus, [data-bgt=gallery] .bgt-gallery-ctrl__prev:focus {
  outline: none
}

[data-bgt=gallery] .bgt-gallery-ctrl__next span, [data-bgt=gallery] .bgt-gallery-ctrl__prev span {
  display: block;
  overflow: hidden;
  width: 0;
  height: 0
}

[data-bgt=gallery] .bgt-gallery-ctrl__next:after, [data-bgt=gallery] .bgt-gallery-ctrl__prev:after {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  border: solid #fff;
  border-width: 5px 5px 0 0
}

[data-bgt=gallery] .bgt-gallery-ctrl__prev {
  left: 20px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg)
}

[data-bgt=gallery] .bgt-gallery-ctrl__next {
  right: 20px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}

[data-bgt=gallery] [data-gallery-marker=thumbs]+.bgt-gallery-marker {
  margin-top: 10px
}

[data-bgt=gallery] [data-gallery-marker=thumbs]+.bgt-gallery-marker li {
  margin: 0 5px;
  width: 60px;
  height: 60px
}

[data-bgt=gallery] [data-gallery-marker=dot]+.bgt-gallery-marker {
  margin-top: 10px
}

[data-bgt=gallery] [data-gallery-marker=dot]+.bgt-gallery-marker li {
  margin: 0 2px;
  width: 10px;
  height: 10px;
  background-color: #999;
  border-radius: 100%
}

[data-bgt=gallery] [data-gallery-marker=dot]+.bgt-gallery-marker li.current {
  background-color: #000
}

.pc-only {
  display: block
}

@media screen and (max-width:768px) {
  .pc-only {
    display: none
  }
}

.sp-only {
  display: none
}

@media screen and (max-width:768px) {
  .sp-only {
    display: block
  }
}