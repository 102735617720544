$black: #000;
$gray: #8D8D8D;
$light-gray: #F5F5F5;
$pink: #E8316E;
$light-pink: #FDF0F5;
$yellow: #FFE76A;
$light-yellow: #FFFAE3;
$blue: #24B8BC;
$light-blue: #E5F9FA;
$green: #79BF07;
$light-green: #F4FBEB;
$orange: #F6680F;

$grad1: linear-gradient(-90deg, #e62162 0%, #ed4988 100%);
$grad2: linear-gradient(90deg, #ffef9f 0%, #fce67d 100%);
$grad3: linear-gradient(-90deg, #90d127 0%, #79bf07 100%);
$grad4: linear-gradient(-90deg, #f8aa2d 0%, #f69d0f 100%);;

$base-lh: 2;
$base-fs: 18px;
$sp-fs: 15px;

$mincho: "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "ＭＳ 明朝", serif;
$gothic: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "メイリオ", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

$font-notosans: 'Noto Sans JP', sans-serif; /* Regular 400, Bold 700 */
$font-notoserif: 'Noto Serif JP', serif; /* Medium 500 */
$font-poppins: 'Poppins', sans-serif;
